/* eslint-disable react/require-default-props */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { Element } from 'react-scroll';

import { Box, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';

import ResultsStore from '../../../store/SearchResults';
import ResultsActions from '../../../store/SearchResults/actions';
import useChatRIAConfig from '../../../components/ChatRIA/hooks/useChatRIAConfig';
import AriaCard from '../../../components/ui/cards/AriaCard';
import DocumentsViewTitle from './DocumentsViewTitle';
import SourceDropDown from '../../Home/components/SourceDropDown';

import {
  getDocumentFlag,
  getDocumentFlagTooltipTitle,
  prepareDocumentCard,
  scrollToResult
} from '../utils/documentResultsUtils';
import { SOURCE_DROPDOWN_TYPES, SOURCE_MENU_ITEMS } from '../../Home/const';

import useDocumentsViewFunctions from '../hooks/useDocumentsViewFunctions';

import {
  EMPTY_RESULTS_MESSAGES,
  scoreConfidenceMapper,
  scoreConfidenceMapperTooltip,
  DOCUMENT_DATA_SOURCE_CHIP_OPTIONS
} from '../constants';
import Loading from './Loading';
import EmptyResult from './EmptyResult';
import DocumentRightGrid from './DocumentRightGrid';
import DidYouMeanText from './DidYouMeanText';
import useUpdateModule from '../../../hooks/useUpdateModule';
import CustomChip from '../../AdcommModule/CustomChip';
import DocumentsDownloadButton from '../Buttons/DocumentsDownloadButton';
import ShareButton from '../Buttons/ShareButton';
import applicationCardStyles from '../styles/ApplicationCard.styles';
import DocumentsReviewMenu from './DocumentsReviewMenu';
import FavoriteAndProjectActions from '../../../components/FavoritesAndProjectsAction/FavoriteAndProjectActions';
import RESULT_VIEW_TYPES from './constants';
import SearchSuggestions from './SearchSuggestions';

// eslint-disable-next-line react/require-default-props
const DocumentsView = ({
  showSourceDropdown = true,
  onDidYouMeanTextClick,
  renderedInPopup = false,
  handleAriaShare
}: {
  showSourceDropdown?: boolean; // eslint-disable-next-line no-unused-vars
  onDidYouMeanTextClick?: (param: any) => void;
  renderedInPopup?: boolean; // eslint-disable-next-line no-unused-vars
  handleAriaShare?: (param: any) => void;
}) => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { payload }: any = useParams();

  const [filteredResults, setFilteredResults] = useState([]); // filtered results
  useUpdateModule('documents');
  const { deleteAllChatRIAConfig } = useChatRIAConfig();
  const {
    handleResultClick,
    getDocumentResultTitle,
    sourceName,
    totalCount,
    handleShareClick,
    handleFavoriteClick,
    handleProjectClick,
    handleSortBy,
    selectedSort,
    handleUniqueResultsToggle,
    exploreSelectedFilters,
    setExploreSelectedFilters,
    selectedSources,
    handleSourceChange,
    makeSearch,
    sourceDropDownAnchorEl,
    setSourceDropDownAnchorEl,
    handleDidYouMeanTextClick,
    handleAriaResponseClick
  } = useDocumentsViewFunctions();

  useEffect(() => {
    getDocumentResultTitle();
    return () => {
      deleteAllChatRIAConfig();
    };
  }, [resultsState.documentResults]);

  const childRefs = Array(100)
    .fill(null)
    .map(() => useRef(null)); // used to create ref for each aria result item

  useEffect(() => {
    const ariaResponseResultId = resultsState.selectedAriaResponse?.resultId;
    scrollToResult(ariaResponseResultId, filteredResults, renderedInPopup);
  }, [resultsState.selectedAriaResponse]);

  useEffect(() => {
    const selectedDocumentResultId = resultsState.scrollResultId?.resultId;
    scrollToResult(selectedDocumentResultId, filteredResults, renderedInPopup);
  }, [resultsState.scrollResultId]);

  const filterResultsBasedOnExplore = (results: any, selectedFiltersInfo: any) => {
    let newResults = [...results];
    selectedFiltersInfo?.selectedFiltersOrder?.forEach((category: any) => {
      newResults = newResults?.filter(result =>
        selectedFiltersInfo?.selectedFilters[category]?.some((option: any) =>
          Array.isArray(result[category])
            ? result[category].includes(option)
            : result[category] === option
        )
      );
    });
    return newResults;
  };
  // memoized filtered results

  const filteredMemoResults = () => {
    const results = isEmpty(exploreSelectedFilters)
      ? resultsState.documentResults?.results
      : filterResultsBasedOnExplore(resultsState.documentResults?.results, exploreSelectedFilters);

    // reset show unique results switch when explore filters are applied
    resultsDispatch({
      type: ResultsActions.SET_SHOW_UNIQUE_RESULTS,
      value: {
        enabled: false,
        resultCount: null
      }
    });

    resultsDispatch({
      type: ResultsActions.SET_EXPLORE_PAGE_RESULTS_COUNT,
      value: results?.length ?? 0
    });
    // Extracting result_ids currently in active view into a list
    const resultIds = results?.map((res: any) => res.result_id) ?? [];
    resultsDispatch({ type: ResultsActions.SET_RESULT_IDS_IN_VIEW, value: resultIds });

    setFilteredResults(results);
  };

  const didYouMeantext = resultsState.documentResults.did_you_mean;

  const getSearchId = useCallback((): string => {
    switch (resultsState?.viewType) {
      case RESULT_VIEW_TYPES.APPLICATION:
        return resultsState?.searchIds?.applicationSearchId;
      case RESULT_VIEW_TYPES.DOCUMENT:
        return resultsState?.searchIds?.documentSearchId;
      case RESULT_VIEW_TYPES.CT:
        return resultsState?.searchIds?.ctSearchId;
      default:
        return '';
    }
  }, [resultsState?.viewType, resultsState?.searchIds]);

  const favoriteInvokedBy = useMemo(() => {
    if (resultsState.decryptedPayload?.searchType === 'advanced') {
      return 'advancedSearch';
    }
    if (
      resultsState.viewType === RESULT_VIEW_TYPES.APPLICATION ||
      resultsState.viewType === RESULT_VIEW_TYPES.CT
    ) {
      return 'applicationSearch';
    }
    if (resultsState.viewType === RESULT_VIEW_TYPES.DOCUMENT) {
      return 'documentSearch';
    }

    return 'search';
  }, [resultsState.decryptedPayload, resultsState.viewType]);

  useEffect(() => {
    if (!isEmpty(resultsState.documentResults)) {
      filteredMemoResults();
    }
  }, [resultsState.documentResults, exploreSelectedFilters, resultsState.documentResults?.results]);

  if (resultsState?.isContentLoading) {
    return <Loading />;
  }
  /**
   * Checks if the provided object is considered empty based on custom criteria.
   * The object is considered empty if it is an empty object ({}) or if the 'total_results' property is 0.
   *
   * @param {any} obj - The object to check for emptiness.
   * @returns {boolean} - True if the object is considered empty, false otherwise.
   */
  const isCustomEmpty = (obj: any): boolean => isEmpty(obj) || obj?.total_results === 0;

  // If there are no applications and no filters applied, show search suggestions if available
  if (
    isCustomEmpty(resultsState.documentResults) &&
    isEmpty(resultsState.filters) &&
    resultsState?.searchSuggestions?.length > 0
  ) {
    return <SearchSuggestions />;
  }

  if (isCustomEmpty(resultsState.documentResults)) {
    return (
      <EmptyResult
        firstMessage={
          Object.keys(resultsState.filters)?.length > 0
            ? EMPTY_RESULTS_MESSAGES.filterEmptyMessage.firstMessage
            : EMPTY_RESULTS_MESSAGES.GeneralSearchEmptyMessage.firstMessage
        }
        secondMessage={
          Object.keys(resultsState.filters)?.length > 0
            ? EMPTY_RESULTS_MESSAGES.filterEmptyMessage.secondMessage
            : EMPTY_RESULTS_MESSAGES.GeneralSearchEmptyMessage.secondMessage
        }
        actionText={
          didYouMeantext && (
            <Box>
              <DidYouMeanText
                text={didYouMeantext}
                onClick={onDidYouMeanTextClick || handleDidYouMeanTextClick}
                fontStyle={{ fontSize: 16 }}
              />
            </Box>
          )
        }
      />
    );
  }

  const getScoreConfidence = (data: any) => {
    if (data.type === 'answer') {
      return scoreConfidenceMapper.answer;
    }
    return scoreConfidenceMapper[data.score_confidence?.toLowerCase()];
  };

  const getScoreConfidenceTooltip = (data: any) => {
    if (data.type === 'answer') {
      return scoreConfidenceMapperTooltip.answer;
    }
    return scoreConfidenceMapperTooltip[data.score_confidence?.toLowerCase()];
  };

  const getFlagIconWithTooltip = (data: any) => {
    const FlagIcon = getDocumentFlag(data.data_source || data['data-source']);
    const flagTooltipTitle = getDocumentFlagTooltipTitle(data.data_source || data['data-source']);
    return FlagIcon ? (
      <Tooltip title={flagTooltipTitle}>
        <FlagIcon sx={{ fontSize: 18 }} />
      </Tooltip>
    ) : null;
  };

  const shouldRenderResult = (data: any) => {
    if (resultsState.showUniqueResults.enabled && data?.is_duplicate) return false;
    return true;
  };

  const getCderOrCdrhChip = (modifiedData: any) => {
    if (modifiedData?.category_bucket !== 'Adcomm') {
      return null;
    }

    let label = 'CDER'; // Default label

    if (modifiedData?.data_source?.includes('CDRH')) {
      label = 'CDRH';
    } else if (modifiedData?.data_source?.includes('CBER')) {
      label = 'CBER';
    }

    return <CustomChip label={label} />;
  };

  const getAriaResponse = () => {
    if (resultsState.showTop10Summary) {
      return null;
    }

    if (Object.keys(resultsState.selectedAriaResponse).length === 0) {
      return prepareDocumentCard(resultsState.documentResults.results[0]);
    }

    return resultsState.selectedAriaResponse;
  };

  return (
    <Stack
      sx={{
        ml: 2,
        mr: 2,
        overflowY: 'hidden'
      }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Stack direction='row' spacing={1} alignItems='center'>
          {payload && showSourceDropdown && (
            <SourceDropDown
              selectedSources={selectedSources}
              handleChange={handleSourceChange}
              options={SOURCE_MENU_ITEMS}
              anchorEl={sourceDropDownAnchorEl}
              setAnchorEl={setSourceDropDownAnchorEl}
              type={SOURCE_DROPDOWN_TYPES.DOCUMENTS_VIEW}
              miniminumOneSelected
              showApplyButton
              makeSearch={makeSearch}
            />
          )}
          {payload && showSourceDropdown && (
            <Typography variant='subtitle1' sx={{ color: 'gray.contrastText' }}>
              |
            </Typography>
          )}
          <DocumentsViewTitle
            sourceName={sourceName}
            totalCount={
              resultsState?.showUniqueResults?.resultCount ??
              resultsState?.exploreResultLength ??
              totalCount
            }
            overallCount={resultsState?.documentResults?.total_results}
          />
        </Stack>
        <Stack direction='row' justifyContent='flex-end'>
          <Stack direction='row' alignItems='center'>
            <DocumentsReviewMenu
              handleSortBy={handleSortBy}
              selectedSort={selectedSort}
              handleUniqueResultsToggle={handleUniqueResultsToggle}
              filteredResults={filteredResults}
            />
            <Divider orientation='vertical' sx={applicationCardStyles.verticalDivider} />
            {!renderedInPopup && (
              <FavoriteAndProjectActions
                invokedBy={favoriteInvokedBy}
                source=''
                id={getSearchId()}
                isButton
                isFavorite={resultsState?.isFavorite ?? false}
                inProjects={resultsState?.inProjects ?? []}
                setFavoriteInHook={({ isFavorite }: { isFavorite: boolean }) => {
                  resultsDispatch({ type: ResultsActions.SET_IS_FAVORITE, value: isFavorite });
                }}
                setInProjectInHook={({ project }: { project: any }) => {
                  let projects = [];
                  if (project?.inProject) {
                    projects = resultsState?.inProjects.filter(
                      (p: any) => (p?.project_id ?? p?.id) !== (project?.project_id ?? project?.id)
                    );
                  } else {
                    projects = [
                      ...(resultsState?.inProjects ?? []),
                      {
                        id: project?.project_id ?? project?.id,
                        name: project?.name
                      }
                    ];
                  }
                  resultsDispatch({ type: ResultsActions.SET_IN_PROJECTS, value: projects });
                }}
              />
            )}
            <DocumentsDownloadButton />
            {renderedInPopup ? (
              <ShareButton
                searchType={resultsState.decryptedPayload?.searchType ?? 'aria'}
                search={resultsState.decryptedPayload?.query}
                handleAriaShare={handleAriaShare}
              />
            ) : (
              <ShareButton
                searchType={resultsState.decryptedPayload?.searchType ?? ''}
                search={resultsState.decryptedPayload?.query}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      {didYouMeantext && (
        <Box ml={1}>
          <DidYouMeanText
            text={didYouMeantext}
            onClick={onDidYouMeanTextClick || handleDidYouMeanTextClick}
          />
        </Box>
      )}
      <Grid
        maxWidth='lg-md'
        m='auto'
        id={renderedInPopup ? 'scroll-container-drawer' : 'scroll-container'}
        container
        sx={{
          mt: 2,
          height: resultsState.selectedDocument || resultsState.documentLoader ? '78vh' : '70vh',
          overflowY: 'auto'
        }}>
        <Grid
          item
          xs={resultsState.selectedDocument || resultsState.documentLoader ? 6 : 7}
          sx={{ bgcolor: 'white.main' }}>
          <Stack spacing={2}>
            {filteredResults?.map((data: any, index: number) => {
              // when show unique results is true, we are not showing duplicate results
              if (!shouldRenderResult(data)) return null;
              if (resultsState.showUniqueResults.enabled && data?.is_duplicate) return null;

              const ScoreConfidence = getScoreConfidence(data);
              const scoreConfidenceTooltipTitle = getScoreConfidenceTooltip(data);
              const flag = getFlagIconWithTooltip(data);

              const modifiedData = {
                ...data,
                scrollIndex: index
              };

              return (
                <Stack key={modifiedData.result_id}>
                  <Element name={`child-${index}`} />
                  <Stack ref={childRefs[index]}>
                    <AriaCard
                      key={modifiedData.result_id}
                      handleShareClick={handleShareClick}
                      handleFavoriteClick={handleFavoriteClick}
                      handleProjectClick={handleProjectClick}
                      searchId={resultsState?.searchIds?.documentSearchId || ''}
                      documentCardDetails={prepareDocumentCard(modifiedData)}
                      isSelected={resultsState.selectedDocument?.resultId === data.result_id}
                      isPdfViewOpen={resultsState.selectedDocument !== null}
                      handleResultClick={handleResultClick}
                      handleAriaResponseClick={handleAriaResponseClick}
                      ariaResponse={getAriaResponse()}
                      ariaResponseLoading={resultsState.ariaResponseLoading}
                      flag={flag}
                      scoreConfidence={
                        <Tooltip title={scoreConfidenceTooltipTitle}>
                          <ScoreConfidence sx={{ fontSize: 14 }} />
                        </Tooltip>
                      }
                      documentDataSourceChipOptions={
                        modifiedData['data-source']
                          ? DOCUMENT_DATA_SOURCE_CHIP_OPTIONS[modifiedData['data-source']]
                          : {}
                      }
                      cderOrCdrhChip={getCderOrCdrhChip(modifiedData)}
                    />
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </Grid>
        {/* required to match spacing in wireframe */}
        {!resultsState?.selectedDocument && !resultsState.documentLoader ? (
          <Grid item xs={1} />
        ) : null}

        <Grid
          item
          xs={resultsState.documentLoader || resultsState?.selectedDocument ? 6 : 4}
          sx={{
            overflowY: 'auto',
            height: '100%',
            position: 'sticky',
            top: 0,
            alignSelf: 'flex-start'
          }}>
          <DocumentRightGrid
            exploreSelectedFilters={exploreSelectedFilters}
            setExploreSelectedFilters={setExploreSelectedFilters}
            containerId={renderedInPopup ? 'scroll-container-drawer' : 'scroll-container'}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default DocumentsView;
