/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { prepareCSVData, prepareData } from './columnOrder';

// headers based on source for excel download
// Should be moved to an application level utils file.
const getCurrentDate = () => {
  const today = new Date();
  let dd = today.getDate();

  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${mm}-${dd}-${yyyy}`;
};

const handleDownload = async (
  value,
  results,
  source,
  module,
  fileHeader = [],
  uniqueResults = false
) => {
  if (!results) {
    throw new Error('No data found to be downloaded');
  }
  // replace the bold html tags from text
  if (uniqueResults) {
    results = results.filter(item => item.is_duplicate === false);
  }

  results.forEach(
    item =>
      (item.text = item.text?.replaceAll('<b>', '')?.replaceAll('</b>', '')?.replaceAll('\n', ''))
  );

  if (value === 'excelFile') {
    const [data, widths] = prepareData(source, results, fileHeader);
    const XLSX = await import('xlsx');
    const workSheet = XLSX.utils.aoa_to_sheet(data);
    const workBook = XLSX.utils.book_new();
    workSheet['!cols'] = widths;
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1');
    return XLSX.writeFile(workBook, `Aria_Results_${module}_${getCurrentDate()}.xlsx`);
  }
  const [csvData, columnHeaders] = prepareCSVData(source, results, fileHeader);

  const { ExportToCsv } = await import('export-to-csv');
  const options = {
    fieldSeparator: value === 'commaFile' ? ',' : ';',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: fileHeader.length > 0,
    title: fileHeader.join('\n'),
    useTextFile: false,
    useBom: true,
    // headers: columnHeaders,
    filename: `Aria_Results_${module}_${getCurrentDate()}`,
    useKeysAsHeaders: true
  };

  const csvExporter = new ExportToCsv(options);
  return csvExporter.generateCsv(csvData);
};

export default handleDownload;
