/* eslint-disable react/require-default-props */
import React, { useEffect, useContext } from 'react';
import { Divider, Stack, Box, Typography, Tooltip, Popover } from '@mui/material';
import comparisonClasses from './styles/LabelSelection.style';
import useDetectNonChromeBrowser from '../../hooks/useDetectNonChromeBrowser';
import { DownloadIcon, InfoIconSummary } from '../../assets/svgs/Icons';
import { sourceFlagMapping, sourceFlagMappingTooltip } from '../../pages/SearchResults/constants';
import draftableComparisonViewStyles from './styles/DraftableComparisonView.styles';
import ResultsStore from '../../store/SearchResults';
import Card from '../../pages/SearchResults/components/Card';

interface DraftableComparisonViewPropsType {
  viewerURL: string;
  parentApplicationNumber: string;
  childApplicationNumber: string;
  parentApplicationName?: string;
  childApplicationName?: string;
  onNewCompare: () => void;
  downloadFile: () => void;
  showNewCompare: boolean;
  showFooter?: boolean;
  parentApplicationSource?: string;
  childApplicationSource?: string;
  SwitchIcon?: any;
}

const DraftableComparisonView: React.FC<DraftableComparisonViewPropsType> = ({
  viewerURL,
  onNewCompare,
  downloadFile,
  parentApplicationNumber,
  childApplicationNumber,
  parentApplicationName = '',
  childApplicationName = '',
  showNewCompare = true,
  showFooter = true,
  parentApplicationSource = '',
  childApplicationSource = '',
  SwitchIcon = false
}) => {
  const { detectBrowserAndDisplayDisclaimer } = useDetectNonChromeBrowser();

  useEffect(() => {
    detectBrowserAndDisplayDisclaimer();
  }, []);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [comparisonApplicationsIndex, setComparisonApplicationsIndex] = React.useState<number>(0);

  const handlePopoverOpen = (event: any, index: number) => {
    setAnchorEl(event.currentTarget);
    setComparisonApplicationsIndex(index);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const ParentFlagComponent = sourceFlagMapping[parentApplicationSource];
  const parentFlagTooltipTitle = sourceFlagMappingTooltip[parentApplicationSource];
  const ChildFlagComponent = sourceFlagMapping[childApplicationSource];
  const childFlagTooltipTitle = sourceFlagMappingTooltip[childApplicationSource];

  const { resultsState } = useContext(ResultsStore);

  return (
    <Stack>
      {showFooter && (
        <Stack
          direction='row'
          spacing={2}
          divider={<Divider orientation='vertical' flexItem sx={{ borderColor: 'primary.200' }} />}
          sx={draftableComparisonViewStyles.secondaryHeaderWrapper}
          justifyContent='flex-end'>
          <Stack direction='row' spacing={0.75} onClick={downloadFile} sx={{ cursor: 'pointer' }}>
            <DownloadIcon sx={draftableComparisonViewStyles.downloadIcon} />
            <Typography sx={draftableComparisonViewStyles.downloadComparisonText}>
              Download Comparison
            </Typography>
          </Stack>
        </Stack>
      )}
      <Popover
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none'
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Typography sx={{ p: 1 }}>
          <Card
            data={resultsState?.comparisonApplications?.[comparisonApplicationsIndex]}
            hideApplication={() => {}}
            triggerChatRIA={() => {}}
            setFavoriteInHook={() => {}}
            setInProjectInHook={() => {}}
            setSubscriptionInHook={() => {}}
          />
        </Typography>
      </Popover>
      {/* header titles for iframe pdfs */}
      <Box sx={{ px: '24px' }}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ width: '100%', pr: 6.25, pt: '20px' }}>
          <Stack direction='row' width='50%' zIndex={1}>
            <Stack
              direction='row'
              alignItems='baseline'
              justifyContent='space-between'
              sx={{ ...draftableComparisonViewStyles.titleHeaderWrapper, mr: '30px' }}>
              <Stack direction='row' alignItems='baseline' spacing={0.5}>
                <Tooltip title={parentApplicationName || ''}>
                  <Typography variant='subtitle2' sx={comparisonClasses.titleTab} noWrap>
                    {parentApplicationNumber}
                  </Typography>
                </Tooltip>
                {parentApplicationSource && (
                  <Tooltip
                    title={parentFlagTooltipTitle}
                    disableInteractive
                    disableFocusListener
                    disableTouchListener>
                    <ParentFlagComponent sx={{ width: '18px', height: '13px' }} />
                  </Tooltip>
                )}
              </Stack>
              <Stack>
                <InfoIconSummary
                  onMouseEnter={e => handlePopoverOpen(e, 0)}
                  onMouseLeave={handlePopoverClose}
                  sx={{
                    height: '16px',
                    width: '16px'
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
          {SwitchIcon && <SwitchIcon />}
          <Stack direction='row' width='50%' zIndex={1}>
            <Stack
              direction='row'
              alignItems='baseline'
              justifyContent='space-between'
              sx={{ ...draftableComparisonViewStyles.titleHeaderWrapper, ml: '30px' }}>
              <Stack direction='row' alignItems='baseline' spacing={0.5}>
                <Tooltip title={childApplicationName || ''}>
                  <Typography variant='subtitle2' sx={comparisonClasses.titleTab} noWrap>
                    {childApplicationNumber}
                  </Typography>
                </Tooltip>
                {childApplicationSource && (
                  <Tooltip
                    title={childFlagTooltipTitle}
                    disableInteractive
                    disableFocusListener
                    disableTouchListener>
                    <ChildFlagComponent sx={{ width: '18px', height: '13px' }} />
                  </Tooltip>
                )}
              </Stack>

              <Stack>
                <InfoIconSummary
                  onMouseEnter={e => handlePopoverOpen(e, 1)}
                  onMouseLeave={handlePopoverClose}
                  sx={{
                    height: '16px',
                    width: '16px'
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        {/* draftable embedded iframe */}
        <Box sx={{ height: '74vh', border: '1px solid gray' }}>
          <iframe
            style={comparisonClasses.compareIFrame}
            title='compareDocuments'
            src={viewerURL}
            allowFullScreen
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default React.memo(DraftableComparisonView);
