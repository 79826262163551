import { useContext, useState, MouseEvent, useMemo } from 'react';
import { has } from 'lodash';
import {
  Badge,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Switch,
  Tooltip,
  Typography
} from '@mui/material';
import { ArrowDownIcon, CustomCheckIcon, SortIconVariant } from '../../../assets/svgs/Icons';
import buttonStyles from '../styles/Buttons.styles';
import { RoundedMenu } from '../../../components/ui/Menu';
import ResultsStore from '../../../store/SearchResults';
import MenuItemWithSubMenu from '../../../components/ui/Menu/MenuItemWithSubMenu/MenuItemWithSubMenu';
import { sortMapping, sortValueMapping } from '../constants';
import reviewMenuStyles from '../styles/ReviewMenu.styles';

const DocumentsReviewMenu = ({
  handleSortBy,
  selectedSort,
  handleUniqueResultsToggle,
  filteredResults
}: any) => {
  const { resultsState } = useContext(ResultsStore);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // get the value to map with the string with which we want to sort the document
  const sourceCategory = (): string => {
    if (
      resultsState?.documentResults?.results?.length > 0 &&
      resultsState?.documentResults?.results[0]?.category_bucket
    ) {
      return resultsState.documentResults.results[0]?.category_bucket;
    }
    return 'default';
  };

  const isSortingApplied = useMemo(() => {
    const sortList = sortMapping(sortValueMapping[sourceCategory()]);
    const currentSort = sortList?.find((item: any) => item.id === selectedSort);
    return currentSort?.value !== 'default';
  }, [selectedSort]);

  const isAnyOptionSelected = useMemo(() => {
    return isSortingApplied || resultsState?.showUniqueResults?.enabled;
  }, [selectedSort, resultsState?.showUniqueResults]);

  const disableShowUniqueToggle =
    Object.keys(resultsState?.decryptedSource).length === 1 &&
    has(resultsState?.decryptedSource, 'us') &&
    resultsState?.decryptedSource?.us?.length === 1 &&
    resultsState?.decryptedSource?.us[0] === 'sba';

  return (
    <>
      <Button
        endIcon={<ArrowDownIcon sx={buttonStyles.buttonIcon} />}
        sx={buttonStyles.button}
        onClick={handleOpenMenu}>
        <Badge
          color='primary'
          variant='dot'
          invisible={!isAnyOptionSelected}
          sx={reviewMenuStyles.reviewButtonBadgeDot}>
          Sort & View
        </Badge>
      </Button>
      <RoundedMenu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        sx={reviewMenuStyles.roundedMenu}>
        <MenuItemWithSubMenu
          MenuItemIcon={
            <Badge
              color='primary'
              variant='dot'
              invisible={!isSortingApplied}
              sx={reviewMenuStyles.badgeDot}>
              <SortIconVariant sx={buttonStyles.buttonIcon} />
            </Badge>
          }
          MenuItemText='Sort by'>
          {sortMapping(sortValueMapping[sourceCategory()] || sortValueMapping.default)?.map(
            (item: any) => {
              return (
                <MenuItem
                  key={item.id}
                  onClick={() => {
                    handleSortBy(item);
                    handleCloseMenu();
                  }}
                  sx={reviewMenuStyles.menuItem}>
                  <ListItemIcon sx={reviewMenuStyles.listItemIcon}>
                    <CustomCheckIcon
                      sx={{
                        ...reviewMenuStyles.customCheckIcon,
                        opacity: selectedSort === item.id ? 1 : 0
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      sx={{
                        ...reviewMenuStyles.listItemText,
                        fontWeight: selectedSort === item.id ? 700 : 400
                      }}>
                      {item.id}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              );
            }
          )}
        </MenuItemWithSubMenu>
        <Divider variant='middle' />
        <Tooltip
          title={
            !disableShowUniqueToggle
              ? 'This feature is only available for US-FDA Drugs and biologics search'
              : 'Show unique results'
          }>
          <MenuItem>
            <ListItemText
              sx={{
                ...reviewMenuStyles.listItemText,
                mx: '8px',
                color: !disableShowUniqueToggle ? 'gray.400' : 'gray.800'
              }}>
              Show unique results
            </ListItemText>
            <Switch
              disabled={!disableShowUniqueToggle}
              size='small'
              onChange={event => {
                handleUniqueResultsToggle(event, filteredResults);
                handleCloseMenu();
              }}
              checked={resultsState?.showUniqueResults?.enabled}
              name='Unique Results'
            />
          </MenuItem>
        </Tooltip>
      </RoundedMenu>
    </>
  );
};

export default DocumentsReviewMenu;
