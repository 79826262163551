/* eslint-disable react/require-default-props */
// @ts-nocheck
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Button, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ResultsStore from '../../../store/SearchResults';
import ResultsAction from '../../../store/SearchResults/actions';

import { CloseIcon, ThumbsDownIcon, ThumbsUpIcon, UpArrowIcon } from '../../../assets/svgs/Icons';
import PDFViewer from '../../../components/PDFViewer';

import useDocumentsViewFunctions from '../hooks/useDocumentsViewFunctions';

import styles from '../styles/PdfDetailsView.styles';
import LanguageToggleButton from '../../../components/LanguageToggleComponents/LanguageToggleButton';
import LanguageToggleText from '../../../components/LanguageToggleComponents/LanguageToggleText';

const useWindowSize = () => {
  const [size, setSize] = useState({ offsetHeightOfViewer: 180, widthOfPDFViewer: 670 });
  useLayoutEffect(() => {
    const updateSize = () => {
      const containerDiv = document.getElementById('pdf-viewer-container');
      if (containerDiv) {
        // get  pdf-viewer-div offsetHeight
        // set width of pdf-viewer-div to that of pdf-viewer-container
        setSize({
          offsetHeightOfViewer: containerDiv.getBoundingClientRect().top,
          widthOfPDFViewer: containerDiv.getBoundingClientRect().width
        });
      }
      setSize();
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return { ...size };
};
const PdfDetailsView = ({ fromUserProfile = false }: { fromUserProfile?: boolean }) => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { offsetHeightOfViewer, widthOfPDFViewer } = useWindowSize();
  const pdfViewerRef = useRef(null);

  const { handleResultClick, handleChatRia, documentResultFeedback } = useDocumentsViewFunctions();

  const documentLink = resultsState.originalTextEnabled
    ? resultsState.selectedDocument?.pdfUrlOriginal ?? resultsState.selectedDocument.pdfUrl
    : resultsState.selectedDocument.pdfUrl;

  const isPdf = documentLink
    ? new URL(documentLink).pathname.endsWith('.pdf') || documentLink?.startsWith('blob:')
    : false;

  const toggleTextLanugage = (value: boolean) => {
    resultsDispatch({ type: ResultsAction.SET_ORIGINAL_TEXT_ENABLED, value });
  };
  useEffect(() => {
    if (pdfViewerRef && pdfViewerRef.current && widthOfPDFViewer && offsetHeightOfViewer) {
      pdfViewerRef.current.style.width = `${widthOfPDFViewer - 25}px`;
      pdfViewerRef.current.style.minWidth = `${widthOfPDFViewer - 25}px`;
      pdfViewerRef.current.style.maxWidth = `${widthOfPDFViewer - 25}px`;
      pdfViewerRef.current.style.top = `${offsetHeightOfViewer}px`;
    }
  }, [widthOfPDFViewer, offsetHeightOfViewer]);

  if (resultsState && !resultsState.selectedDocument) {
    return null;
  }

  return (
    <Stack
      spacing={1}
      ref={pdfViewerRef}
      sx={fromUserProfile ? styles.pdfViewerContainerUserProfile : styles.pdfViewerContainer}>
      {/* selected row title */}
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={1} alignItems='center' sx={{ maxWidth: '70%' }}>
          <Typography variant='body1' noWrap sx={styles.title}>
            {resultsState.selectedDocument.resultDetails?.category ??
              // eslint-disable-next-line no-underscore-dangle
              resultsState.selectedDocument.resultDetails?._category}
          </Typography>
          <Divider orientation='vertical' variant='middle' flexItem />{' '}
          <Button
            variant='text'
            disabled={!isPdf}
            onClick={() =>
              handleChatRia({
                resultDetails: resultsState.selectedDocument.resultDetails,
                pdfUrl: documentLink
              })
            }
            sx={styles.chatRiaBtn}
            endIcon={
              <Stack>
                <SendIcon fontSize='small' sx={{ color: 'red', fontSize: 12 }} />
              </Stack>
            }>
            Chat RIA
          </Button>
        </Stack>
        <Stack direction='row' spacing={1} alignItems='center'>
          <IconButton target='_blank' href={documentLink}>
            <Tooltip title='Open in new tab' placement='bottom-end' sx={styles.icon}>
              <UpArrowIcon sx={{ fontSize: '18px' }} />
            </Tooltip>
          </IconButton>
          {resultsState.selectedDocument?.pdfUrlOriginal && (
            <LanguageToggleButton
              source={resultsState.selectedDocument?.resultDetails?.dataSource}
              originalTextEnabled={resultsState.originalTextEnabled}
              toggleTextLanugage={toggleTextLanugage}
              color='green'
            />
          )}
          <Tooltip title='Close' placement='bottom-end'>
            <IconButton
              onClick={() =>
                handleResultClick({
                  onClose: true,
                  resultId: resultsState.selectedDocument.resultId
                })
              }>
              <CloseIcon sx={{ fontSize: 14 }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      {/* pdf document */}
      <Stack sx={styles.pdfViewer} spacing={0.5}>
        {resultsState.originalTextEnabled && <LanguageToggleText styles={{ textAlign: 'start' }} />}
        {isPdf ? (
          <PDFViewer
            fileUrl={documentLink}
            initialPage={documentLink.split('#page=')[1] - 1 ?? 0}
            defaultScale={'PageWidth'}
          />
        ) : (
          <iframe src={documentLink} title='pdf' style={{ width: '100%', height: '90vh' }} />
        )}
      </Stack>

      {/* feedback row */}
      <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          justifyContent='center'
          sx={styles.feedbackContainer}>
          <Typography variant='body1' sx={styles.feedbackText}>
            Help us create better experience for you. Was the result helpful?
          </Typography>
          <IconButton size='small' onClick={() => documentResultFeedback('RELEVANT')}>
            <Tooltip title='Like' placement='bottom-end'>
              <ThumbsUpIcon sx={styles.icon} />
            </Tooltip>
          </IconButton>
          <IconButton size='small' onClick={() => documentResultFeedback('NOT_RELEVANT')}>
            <Tooltip title='Dislike' placement='bottom-end'>
              <ThumbsDownIcon sx={styles.icon} />
            </Tooltip>
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PdfDetailsView;
