const styles = {
  secondaryHeaderWrapper: {
    padding: '8px 24px',
    backgroundColor: 'primary.50'
  },
  downloadIcon: {
    height: '16px',
    width: '16px',
    color: 'primary.700'
  },
  downloadComparisonText: {
    fontFamily: 'Mulish',
    fontSize: '13px',
    color: 'primary.700',
    fontWeight: 600
  },
  titleHeaderWrapper: {
    p: '10px 16px',
    width: '100%',
    backgroundColor: 'gray.200',
    borderRadius: '4px 4px 0px 0px'
  }
};

export default styles;
