import React from 'react';

import { Stack, Typography } from '@mui/material';

import styles from '../styles/DocumentTitle.styles';

const DocumentsViewTitle = ({ sourceName, totalCount, overallCount }: any) => {
  return (
    <Stack>
      <Typography variant='body1' sx={styles.titleText}>
        Showing{' '}
        <Typography component='span' fontWeight='bold'>
          {totalCount}
        </Typography>{' '}
        of{' '}
        <Typography component='span' fontWeight='bold'>
          {overallCount} results{' '}
        </Typography>
        from{' '}
        <Typography component='span' fontWeight='bold'>
          {sourceName}
        </Typography>{' '}
        documents
      </Typography>
    </Stack>
  );
};

export default DocumentsViewTitle;
