/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
import React, { memo, useContext } from 'react';

import { Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { MagicWand } from '../../../assets/svgs/Icons';

import ResultsStore from '../../../store/SearchResults';

import useChatRiaAnswer from '../hooks/useChatRiaAnswer';

import AriaResponse from './AriaResponse';
import ExplorePageFilters from '../../Aria/components/ExplorePageFilters';
import PdfDetailsView from './PdfDetailsView';
import Loading from './Loading';

const DocumentRightGrid = ({
  exploreSelectedFilters,
  setExploreSelectedFilters,
  containerId
}: any) => {
  const { resultsState } = useContext(ResultsStore);
  const showAriaResponse = !resultsState?.selectedDocument && !resultsState.documentLoader;
  const { generateTop10Summary } = useChatRiaAnswer();

  return (
    <Stack spacing={2} id='pdf-viewer-container'>
      {showAriaResponse && (
        <ExplorePageFilters
          selectedFiltersInfo={exploreSelectedFilters}
          setSelectedFiltersInfo={setExploreSelectedFilters}
        />
      )}
      {/* AriaResponse is always mounted, but its visibility is controlled */}
      <div
        style={{
          visibility: showAriaResponse ? 'visible' : 'hidden',
          position: showAriaResponse ? 'static' : 'absolute'
        }}>
        <AriaResponse containerId={containerId} />

        {/* show this button only when top 10 summary results are not in view */}
        {!resultsState.showTop10Summary && (
          <Stack mt={2} px={2} justifyContent='center' alignItems='center' sx={{ maxWidth: 389 }}>
            <LoadingButton
              loadingIndicator='Generating summary…'
              loading={resultsState.top10SummaryLoading}
              onClick={generateTop10Summary}
              variant='outlined'
              startIcon={
                <MagicWand
                  sx={{ fontSize: 12, opacity: resultsState.top10SummaryLoading ? 0.3 : 1 }}
                />
              }
              sx={{
                textTransform: 'capitalize',
                fontSize: 12,
                fontWeight: 600,
                color: 'primary.700',
                borderRadius: '16px',
                mb: 10
              }}>
              Generate key insights from Top Results
            </LoadingButton>
          </Stack>
        )}
      </div>

      {resultsState.documentLoader ? (
        <Stack
          sx={{
            width: '100%',
            height: '75vh'
          }}
          justifyContent='center'
          alignItems='center'>
          <Loading showTips={false} />
        </Stack>
      ) : (
        resultsState?.selectedDocument && (
          <Stack>
            <PdfDetailsView />
          </Stack>
        )
      )}
    </Stack>
  );
};

export default memo(DocumentRightGrid);
