/* eslint-disable no-console */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

import ResultsStore from '../../../store/SearchResults';
import ResultActions from '../../../store/SearchResults/actions';
import { highlightPDF, submitAriaFeedback } from '../../../api/pages/Aria';
import { DOCUMENT_SEARCH_SUBTEXT } from '../constants';
import { getDocumentSourceName, prepareDocumentCard } from '../utils/documentResultsUtils';
import { SourceDropdown } from '../../Home/types';
import {
  createSourceModuleDropdownMapping,
  createSourceModulePayloadMapping,
  getDefaultHomePageSourceDropDown
} from '../../Home/utils';
import { decodeBase64ToObject, encodeObjectToBase64 } from '../../../utils/encodeDecodeObject';

import { Result, SortType } from '../types/documentResultsUtils.types';
import RESULT_VIEW_TYPES from '../components/constants';

const useDocumentsViewFunctions = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(GlobalStore) as any;
  const { payload }: any = useParams();

  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const [totalCount, setTotalCount] = useState();
  const [sourceName, setSourceName] = useState('Multiple source');
  const [selectedSort, setSelectedSort] = useState('Relevance: Very High -> Low');
  // state for explore more selected filters
  const [exploreSelectedFilters, setExploreSelectedFilters] = useState({
    selectedFilters: {},
    selectedFiltersOrder: []
  });
  const [sourceDropDownAnchorEl, setSourceDropDownAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSources, setSelectedSources] = useState<SourceDropdown[]>([]);

  useEffect(() => {
    dispatch({
      type: GlobalActions.TRIGGER_CHATRIA_CLOSE_SESSION,
      value: !state.chatRiaCloseSessionTrigger
    });
    // closing Chat RIA initital sessions if any on mount.
  }, []);

  useEffect(() => {
    // reset the filters when the aria results change
    setExploreSelectedFilters({
      selectedFilters: {},
      selectedFiltersOrder: []
    });
  }, [resultsState.documentResults]);

  useEffect(() => {
    if (payload) {
      const searchPayload: any = decodeBase64ToObject(payload);
      const convertedSelectedSources = createSourceModuleDropdownMapping(
        searchPayload?.source ?? {}
      );
      setSelectedSources(convertedSelectedSources);
    } else {
      setSelectedSources(getDefaultHomePageSourceDropDown());
    }
  }, [payload]);

  /**
   * Handles the click event on a document result by dispatching an action to update the selected document in the state.
   * This function is typically used to manage the state of a document viewer when a user interacts with a document result.
   * It also processes the document to highlight specific text if needed.
   *
   * @param {Object} params - The parameters for handling the result click.
   * @param {string} [params.resultId=''] - The unique identifier for the clicked result.
   * @param {Object} [params.resultDetails={}] - The detailed information about the result.
   * @param {string} [params.pdfUrl=''] - The URL to the PDF document, if applicable.
   * @param {boolean} [params.onClose=false] - A flag indicating whether the action is a close event.
   *
   * Upon clicking a result, the function extracts and processes the text and highlighted words from resultDetails.
   * When a result is clicked, the function dispatches an action to update the application state with the selected document's details.
   * If the onClose flag is true, it indicates the click event is intended to close or deselect the current document, setting the selected document in the state to null.
   * Otherwise, it updates the state with the provided resultId, resultDetails, and pdfUrl.
   *
   * @example
   * Example usage in a component
   * handleResultClick({
   *   resultId: '123',
   *   resultDetails: { title: 'Document Title', date: '2023-01-01',item: { text: 'Sample Text', highlighted_words: ['sample', 'text'] }  },
   *   pdfUrl: 'http://example.com/document.pdf'
   * });
   */
  const handleResultClick = async ({
    resultId = '',
    resultDetails = {},
    pdfUrl = '',
    pdfUrlOriginal = '',
    onClose = false,
    triggerAriaResponse = false
  }: {
    resultId?: string;
    resultDetails?: any;
    pdfUrl?: string;
    pdfUrlOriginal?: string;
    onClose?: boolean;
    triggerAriaResponse?: boolean;
  }) => {
    // If the click was for close, set the selected documents to null to close the pdf viewer
    if (onClose) {
      resultsDispatch({
        type: ResultActions.SET_SELECTED_DOCUMENT,
        value: null
      });
      resultsDispatch({
        type: ResultActions.SET_ORIGINAL_TEXT_ENABLED,
        value: false
      });
      resultsDispatch({
        type: ResultActions.SET_SCROLL_RESULT_ID,
        value: {
          resultId,
          onClose: true
        }
      });
      // If the pdf was opened through verify btn from top 10 summary,
      // then show the top 10 summary again when  pdf is closed
      if (resultsState.riaResponseTitle === 'Key Insights') {
        resultsDispatch({
          type: ResultActions.SET_SHOW_TOP_10_SUMMARY,
          value: true
        });
      }
      return;
    }

    if (!resultsState.resultsIdsInView?.includes(resultId) && state.module === 'documents') {
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: {
          message:
            "Please clear explore filters or disable 'Show Unique Results' to view the desired result.",
          status: true,
          color: 'info'
        }
      });
      return;
    }

    resultsDispatch({ type: ResultActions.SET_DOCUMENT_LOADER, value: true });
    resultsDispatch({
      type: ResultActions.SET_SHOW_TOP_10_SUMMARY,
      value: false
    });
    try {
      if (resultDetails.item.document_url.includes('.txt')) {
        resultsDispatch({
          type: ResultActions.SET_SELECTED_DOCUMENT,
          value: {
            resultId,
            resultDetails,
            pdfUrl: `${resultDetails.item.document_url}`,
            pdfUrlOriginal
          }
        });
        return;
      }
      if (triggerAriaResponse) {
        resultsDispatch({
          type: ResultActions.SET_SELECTED_ARIA_RESPONSE,
          value: resultDetails
        });
        resultsDispatch({
          type: ResultActions.SET_RIA_RESPONSE_TITLE,
          value: 'Selected Result'
        });
      }
      if (resultsState.selectedDocument?.resultId === resultId) {
        return;
      }
      const textToHighlight = resultDetails.item.text.replaceAll('<b>', '').replaceAll('</b>', '');
      const listOfTextToHighlight = resultDetails.item.highlighted_words;
      const highlightPayload = {
        s3_link: pdfUrl,
        text_to_highlight: listOfTextToHighlight,
        phrase_to_highlight: textToHighlight
      };
      const pageNumber = resultDetails.item.document_url?.split('#page=')?.pop() ?? 1;

      const res = await highlightPDF(highlightPayload);
      const fileURL =
        res?.status === 200
          ? URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
          : pdfUrl;

      resultsDispatch({
        type: ResultActions.SET_SELECTED_DOCUMENT,
        value: {
          resultId,
          resultDetails,
          pdfUrl: `${fileURL}#page=${pageNumber}`,
          pdfUrlOriginal
        }
      });
      resultsDispatch({
        type: ResultActions.SET_SCROLL_RESULT_ID,
        value: {
          resultId,
          onClose: false
        }
      });
    } catch (err) {
      console.error(err);
    } finally {
      resultsDispatch({ type: ResultActions.SET_DOCUMENT_LOADER, value: false });
    }
  };

  const handleAriaResponseClick = (selectedResult: any) => {
    resultsDispatch({
      type: ResultActions.SET_SHOW_TOP_10_SUMMARY,
      value: false
    });
    resultsDispatch({
      type: ResultActions.SET_RIA_RESPONSE_TITLE,
      value: 'Selected Result'
    });
    resultsDispatch({
      type: ResultActions.SET_SELECTED_ARIA_RESPONSE,
      value: selectedResult
    });
  };

  /**
   * Determines the source name and total count of document results from the given state.
   * This function updates the state with the name of the source of the documents and the total count of results.
   *
   * - If there's exactly one source, the source name is set to that specific source.
   * - If there are multiple sources, the source name is set to 'Multiple source'.
   * - The total count of documents is also set, defaulting to 0 if undefined.
   */
  const getDocumentResultTitle = async () => {
    const { decryptedSource, documentResults } = resultsState;
    const sourceKeys = Object.keys(decryptedSource);

    if (sourceKeys.length > 1) {
      resultsDispatch({
        type: ResultActions.SET_EXPLORE_PAGE_MAPPER,
        value: { source: '', commonMapper: true }
      });
      setSourceName(DOCUMENT_SEARCH_SUBTEXT['Multiple source']);
    } else {
      let mappedSourceName = sourceKeys[0] ?? state.module;
      if (mappedSourceName === 'eu' || mappedSourceName === 'us' || mappedSourceName === 'ca') {
        const subsetList = decryptedSource[mappedSourceName];
        if (subsetList.length === 1) {
          [mappedSourceName] = subsetList;
          // TODO: This is a temporary fix for the source name mapping.
          // This should be removed in the code cleanup follow up
          if (mappedSourceName === 'sba') {
            mappedSourceName = 'us';
          }
          if (mappedSourceName === 'wr') {
            mappedSourceName = 'fda-written-request';
          }
        } else {
          resultsDispatch({
            type: ResultActions.SET_EXPLORE_PAGE_MAPPER,
            value: { source: '', commonMapper: true }
          });
          setSourceName(DOCUMENT_SEARCH_SUBTEXT['Multiple source']);
          return;
        }
      }
      resultsDispatch({
        type: ResultActions.SET_EXPLORE_PAGE_MAPPER,
        value: { source: mappedSourceName, commonMapper: false }
      });

      setSourceName(DOCUMENT_SEARCH_SUBTEXT[mappedSourceName]);
    }
    setTotalCount(documentResults?.results?.length || 0); // Defaulting to 0 if undefined
  };

  const handleShareClick = async (rowDetails: any) => {
    await dispatch({
      type: GlobalActions.SET_SHARE_LINK,
      value: {
        entityType: 'aria_result_link', // eslint-disable-next-line no-underscore-dangle
        entityDetails: {
          title: rowDetails?.resultDetails?.category,
          text: rowDetails?.resultDetails?.paragraphText,
          s3ResourceLink: rowDetails?.resultDetails?.s3Link
        },
        open: true,
        ariaResultId: rowDetails?.resultId
      }
    });
  };

  /**
   * Handles sorting of search results based on a specified sort type.
   * @param {SortType} sortType - Object containing the sort type details.
   */
  const handleSortBy = async (sortType: SortType): Promise<void> => {
    const { documentResults } = resultsState;
    const { results } = documentResults;

    // Assign 'answer' score to elements of type 'answer'
    const updatedResults =
      results?.map((element: Result) => ({
        ...element,
        score_confidence: element.type === 'answer' ? 'answer' : element.score_confidence
      })) || [];

    // Define custom order for sorting
    const sortOrder = ['answer', 'VERY_HIGH', 'HIGH', 'MEDIUM', 'LOW'];

    // Determine the sorting method
    // eslint-disable-next-line no-unused-vars
    let sortFn: (a: Result, b: Result) => number;

    if (sortType.value === 'default' || selectedSort === sortType.id) {
      // Sort based on the custom order if the selected sort is the same or default
      sortFn = (a, b) =>
        sortOrder.indexOf(a.score_confidence) - sortOrder.indexOf(b.score_confidence);
    } else if (sortType.sortType === 'asc') {
      // Sort based on the specified sort type (ascending or descending)
      sortFn = (a, b) => (a[sortType.value] > b[sortType.value] ? 1 : -1);
    } else {
      sortFn = (a, b) => (b[sortType.value] > a[sortType.value] ? 1 : -1);
    }

    updatedResults.sort(sortFn);

    // Update the selected sort type and document results
    setSelectedSort(sortType.id);
    resultsDispatch({
      type: ResultActions.SET_DOCUMENT_RESULTS,
      value: { ...documentResults, results: updatedResults }
    });
    resultsDispatch({ type: ResultActions.SET_SELECTED_DOCUMENT, value: null });
  };

  const handleChatRia = ({
    resultDetails = {},
    pdfUrl = ''
  }: {
    resultDetails?: any;
    pdfUrl?: string;
  }) => {
    const mappedSourceName = getDocumentSourceName(resultDetails.dataSource);

    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: mappedSourceName
    });

    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        ...resultDetails,
        source: mappedSourceName,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
  };

  const handleFavoriteClick = async ({
    identifier,
    isFavorite
  }: {
    // result ID of the result
    identifier: string;
    isFavorite: boolean;
  }) => {
    const { documentResults } = resultsState;
    const currentRes = documentResults;
    const index = currentRes?.results?.findIndex((x: any) => x?.result_id === identifier) ?? -1;
    if (index === -1) return;
    currentRes.results[index].isFavorite = isFavorite;
    resultsDispatch({ type: ResultActions.SET_DOCUMENT_RESULTS, value: currentRes });
  };

  const handleProjectClick = async ({
    identifier,
    project
  }: {
    // result ID of the result
    identifier: string;
    project: any;
  }) => {
    const { documentResults } = resultsState;
    const currentRes = documentResults;
    const index = currentRes?.results?.findIndex((x: any) => x.result_id === identifier) ?? -1;
    if (index === -1) return;
    if (project?.inProject) {
      currentRes.results[index].inProjects = currentRes.results[index].inProjects.filter(
        (p: any) => (p?.project_id ?? p?.id) !== (project?.project_id ?? project?.id)
      );
    } else {
      currentRes.results[index].inProjects = [
        ...currentRes.results[index].inProjects,
        { id: project?.project_id ?? project?.id, name: project?.name }
      ];
    }
    resultsDispatch({ type: ResultActions.SET_DOCUMENT_RESULTS, value: currentRes });
  };

  /**
   * Toggles the display of unique document results.
   * It filters the results to exclude items marked as duplicates and dispatches an action to update the state.
   * This updated state includes the flag to show or hide unique results and the count of unique results.
   */
  const handleUniqueResultsToggle = async (
    event: React.ChangeEvent<HTMLInputElement>,
    filteredResults: any
  ) => {
    const uniqueResults = filteredResults?.filter((item: any) => !item.is_duplicate);

    resultsDispatch({
      type: ResultActions.SET_SHOW_UNIQUE_RESULTS,
      value: {
        enabled: !resultsState.showUniqueResults.enabled,
        resultCount: event.target.checked ? uniqueResults?.length : null
      }
    });
    if (event.target.checked) {
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: { message: 'Showing unique results', status: true, color: 'success' }
      });
    }
  };

  /**
   * Updates the state with the selected sources.
   * @param {SourceDropdown[]} values - The selected items from the source dropdown.
   */
  const handleSourceChange = (values: SourceDropdown[]) => {
    setSelectedSources(values);
  };

  /**
   * Makes a search based on the selected sources.
   * This function performs the following actions:
   * - Closes the dropdown menu.
   * - Converts the selected sources for the search payload.
   * - Encodes the updated payload and updates the URL.
   * - Dispatches an action to reset or update document results.
   * - Initiates fetching of new document results based on the updated payload.
   *
   */
  const makeSearch = useCallback(
    (sources?: SourceDropdown[]) => {
      setSourceDropDownAnchorEl(null);
      // this check is necessary as if no filter is present and we are setting isSourceChangedWithFilters true by changing the source
      // then useEffect in useFetchResults custom hook won't be executed
      if (Object.keys(resultsState?.filters)?.length > 0) {
        resultsDispatch({ type: ResultActions.SET_FILTERS, value: {} });
        resultsDispatch({ type: ResultActions.SET_IS_SOURCE_CHANGED_WITH_FILTERS, value: true });
      }
      const finalSelectedSources = sources ?? selectedSources;
      const convertedSelectedSources = createSourceModulePayloadMapping(finalSelectedSources);
      const searchPayload: any = decodeBase64ToObject(payload);
      const newPayload = {
        ...searchPayload,
        source: convertedSelectedSources,
        // need to reset the search ids as when source is changed the search should be happened again
        application_search_id: '',
        ct_search_id: ''
      };
      // need to reset the ct results as when source is changed the older data should be reset as we are checking the row id from the results
      resultsDispatch({ type: ResultActions.SET_CT_RESULTS, value: [] });
      const encodedPayload = encodeObjectToBase64(newPayload) ?? '';
      resultsDispatch({
        type: ResultActions.SET_DOCUMENT_RESULTS,
        value: {}
      });
      resultsDispatch({ type: ResultActions.SET_SELECTED_DOCUMENT, value: null });
      history.push(`/search/${encodedPayload}`);
    },
    [selectedSources, payload]
  );

  /**
   * Submits user feedback for a document result and updates the global state to show a toast message.
   * @param {string} feedbackType - User's reaction to the document result (e.g., 'NOT_RELEVANT', 'RELEVANT').
   */
  const documentResultFeedback = async (feedbackType: string) => {
    (await submitAriaFeedback('us' as string, {
      query_id: resultsState.selectedDocument.resultDetails.queryId,
      result_id: resultsState.selectedDocument.resultId,
      user_reaction: feedbackType
    })) as any;

    dispatch({
      type: GlobalActions.SET_ALERT,
      value: { message: 'Thank you for your feedback!', status: true, color: 'success' }
    });
  };

  const handleDidYouMeanTextClick = () => {
    const didYouMeantext = resultsState.documentResults.did_you_mean;
    const search = didYouMeantext?.replace(/<\/?[^>]+(>|$)/g, ''); // Remove html tags
    resultsDispatch({ type: ResultActions.SET_IS_CONTENT_LOADING, value: true });

    const decodedPayload: any = decodeBase64ToObject(payload);
    decodedPayload.search_term = search;
    decodedPayload.view_type = RESULT_VIEW_TYPES.DOCUMENT;
    history.push(`/search/${encodeObjectToBase64(decodedPayload)}`);
  };

  const findDocumentByResultId = (resultId: string) => {
    const hoverResult = resultsState.documentResults.results.find(
      (resulItem: any) => resulItem.result_id === resultId
    );

    const documentCardDetails = prepareDocumentCard(hoverResult);
    return documentCardDetails;
  };
  return {
    handleResultClick,
    getDocumentResultTitle,
    sourceName,
    totalCount,
    exploreSelectedFilters,
    setExploreSelectedFilters,
    handleShareClick,
    handleSortBy,
    selectedSort,
    handleChatRia,
    handleFavoriteClick,
    handleProjectClick,
    handleUniqueResultsToggle,
    handleSourceChange,
    makeSearch,
    selectedSources,
    sourceDropDownAnchorEl,
    setSourceDropDownAnchorEl,
    documentResultFeedback,
    handleDidYouMeanTextClick,
    handleAriaResponseClick,
    findDocumentByResultId
  };
};

export default useDocumentsViewFunctions;
