import React, {
  useMemo,
  useState,
  useCallback,
  useContext,
  memo,
  useEffect,
  MouseEvent
} from 'react';
import {
  Stack,
  Box,
  Divider,
  IconButton,
  Typography,
  Drawer,
  Button,
  TextField,
  InputAdornment,
  Badge
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { startCase, debounce, cloneDeep, difference } from 'lodash';

import VirtualizedGrid from '../../SearchResults/components/CardsVirtualScroll';

import ResultActions from '../../../store/SearchResults/actions';
import ResultsStore from '../../../store/SearchResults';
import Actions from '../../../store/actions';
import GlobalStore from '../../../store';

import VivproDatagrid from '../../../components/Datagrid';
import { cnfToAdvancedSearch } from '../../../components/Header/utils/advanceSearchHelpers';

import { getGenericSearch, getPdfResource } from '../../../api/pages/ResultsPage';
import DatagridIdentifier from '../../../components/Datagrid/DatagridIdetifier';
import DatagridSourceFlag from '../../../components/Datagrid/DatagridSourceFlag';
import DatagridProductNameReg360 from '../../../components/Datagrid/DatagridProductNameReg360';
import DatagridTooltip from '../../../components/Datagrid/DatagridTooltip';
import renderCellExpand from '../../../components/Datagrid/DatagridPopover';
import titleCase from '../../../utils/titleCase';
import ComparisonDialogComp, {
  ModeOfComparisonDialog
} from '../../../components/Comparison/ComparisonDialog';
import SourceDropDown from '../../Home/components/SourceDropDown';
import useEntities from '../../SearchResults/hooks/useEntities';
import { SOURCE_DROPDOWN_TYPES } from '../../Home/const';
import { getApplicationsSourceDropdown, createSourceModulePayloadMapping } from '../../Home/utils';
import DropDownButton from '../../SearchResults/components/DropDownButton';
import { MagnifyingGlassIcon, ArrowDownIcon, SortIconVariant } from '../../../assets/svgs/Icons';
import {
  apiDataToCardProps,
  VIEW_AS_OPTIONS,
  COMPARE_LABEL_TYPES
} from '../../SearchResults/constants';
import entityHandler from '../../SearchResults/utils/entityHandler';
import useApplications from '../../SearchResults/hooks/useApplications';
import { RoundedMenu, MenuWithTickOptions } from '../../../components/ui/Menu';
import MenuItemWithSubMenu from '../../../components/ui/Menu/MenuItemWithSubMenu/MenuItemWithSubMenu';
import { ListViewIcon } from '../../../assets/svgs/Icons/ResultsViewType';
import PDFComparisonDialog from '../../../components/Comparison/PDFComparisonDialog';

const mode: ModeOfComparisonDialog = {
  child_labelList_dependent_on_parent: false,
  section_binding: false,
  allsections: true,
  default_section_all_section: true
};

const SelectionView = ({
  open,
  onClose,
  applicationList,
  sources,
  comparisonApplications,
  showSecondaryNavigation = false
}: any) => {
  const { sortBy, sortOptions, applications } = useApplications();
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { dispatch } = useContext(GlobalStore) as any;
  const [openComparisonView, setOpenComparisonView] = useState(false);
  const [sourceDropDownAnchorEl, setSourceDropDownAnchorEl] = useState<null | HTMLElement>(null);
  const [localSearchQuery, setLocalSearchQuery] = useState('');
  const [localApplicationList, setLocalApplicationList] = useState(applications);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [viewOptions, setViewOptions] = useState<any>(cloneDeep(VIEW_AS_OPTIONS));
  const [currentViewType, setCurrentViewType] = useState('grid');

  const {
    selectedSources,
    handleSourceChange,
    entityCategory,
    entityCategoryOptions,
    applyCategoryEntity,
    entityValue,
    entityTextOptions
  } = useEntities();

  const { handleCompareView, handleLabelSection } = useApplications();

  const [localEntityTextOptions, setLocalEntityTextOptions] = useState<any>(entityTextOptions);
  const [localEntityValue, setLocalEntityValue] = useState(entityValue);
  const [applicationData, setApplicationData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string[]>(['']);

  useEffect(() => {
    setLocalEntityTextOptions(entityTextOptions);
  }, [entityTextOptions]);

  useEffect(() => {
    if (resultsState?.decryptedPayload) {
      let term = [];
      if (resultsState?.decryptedPayload?.searchType === 'advanced') {
        term =
          cnfToAdvancedSearch(resultsState?.decryptedPayload?.query)?.advanceSearchRowData?.map(
            (item: any) => item?.query
          ) ?? [];
      } else {
        term = [resultsState?.decryptedPayload?.query];
      }
      setSearchTerm(term);
    }
  }, [resultsState?.decryptedPayload]);

  const applicationSourceDropdown = useMemo(
    () => getApplicationsSourceDropdown(resultsState.labelComparisonSelection),
    [resultsState.labelComparisonSelection]
  );

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const selectedSort = useMemo(() => sortOptions.find((opt: any) => opt.selected), [sortOptions]);

  const isAnyOptionSelected = useMemo(() => {
    return (selectedSort?.selected && selectedSort?.id !== 'default') || currentViewType === 'list';
  }, [selectedSort, viewOptions]);

  const columnDefs: GridColDef[] = [
    {
      field: 'identifier',
      headerName: 'Application Number',
      headerClassName: 'table-header',
      width: 180,
      cellClassName: 'table-row',
      renderCell: DatagridIdentifier
    },
    {
      field: 'source',
      headerName: 'Source',
      headerClassName: 'table-header',
      width: 100,
      cellClassName: 'table-row',
      renderCell: DatagridSourceFlag
    },
    {
      field: 'product_name',
      headerName: 'Product Name',
      headerClassName: 'table-header',
      width: 180,
      cellClassName: 'table-row',
      valueGetter: ({ row }) => titleCase(`${row.product_name}`),
      renderCell: DatagridProductNameReg360
    },
    {
      field: 'generic_name',
      headerName: 'Generic Name',
      headerClassName: 'table-header',
      width: 180,
      cellClassName: 'table-row',
      valueGetter: ({ row }) => `${row.generic_name}`,
      renderCell: DatagridTooltip
    },
    {
      field: 'sponsor_name',
      headerClassName: 'table-header',
      headerName: 'Sponsor Name',
      width: 180,
      cellClassName: 'table-row',
      valueGetter: ({ row }) => `${row.sponsor_name}`,
      renderCell: DatagridTooltip
    },
    {
      field: 'sponsor_name',
      headerClassName: 'table-header',
      headerName: 'Sponsor Name',
      width: 180,
      cellClassName: 'table-row',
      valueGetter: ({ row }) => `${row.sponsor_name}`,
      renderCell: DatagridTooltip
    },
    {
      field: 'approval_year',
      headerClassName: 'table-header',
      headerName: 'First Approval Year ',
      width: 170,
      cellClassName: 'table-row'
    },
    {
      field: 'indications_and_usage',
      headerClassName: 'table-header',
      headerName: 'Indications ',
      width: 240,
      cellClassName: 'table-row',
      valueGetter: ({ row }) => `${row.indications_and_usage}`,
      renderCell: renderCellExpand
    }
  ];

  const applicationListToShow = useMemo(() => {
    return localSearchQuery ? localApplicationList : applicationList;
  }, [localSearchQuery, localApplicationList, resultsState?.applicationResults, sortOptions]);

  const handleApplicationsSearch = async (payload: any) => {
    const applicationsResult = await getGenericSearch(payload);

    const resultApplications = applicationsResult?.data?.body?.result || {};

    resultsDispatch({ type: ResultActions.SET_DECRYPTED_PAYLOAD, value: payload });

    const viewTypeMetadata = resultApplications?.data?.body?.view_type_metadata ?? {};

    const entityData = entityHandler(resultApplications?.data?.body?.view_type_metadata ?? []);

    setLocalEntityTextOptions(entityData);

    resultsDispatch({
      type: ResultActions.SET_ENTITY_LIST,
      value: viewTypeMetadata
    });

    resultsDispatch({
      type: ResultActions.SET_SEARCH_SUGGESTIONS,
      value: applicationsResult?.data?.body?.alternate_results ?? []
    });

    let apps: any = [];
    const resultSources: any = [];
    Object.keys(resultApplications).forEach(source => {
      resultSources.push(source);
      if (apiDataToCardProps[source]) {
        const sourceApps = resultApplications[source].results.map((appData: any) =>
          apiDataToCardProps[source]?.(appData)
        );
        apps = [...apps, ...sourceApps];
      }
    });

    resultsDispatch({
      type: ResultActions.SET_APPLICATION_RESULTS,
      value: apps
    });
  };

  const makeNewSearch = useCallback(
    async (dropDownSelectedSources: any) => {
      const finalSelectedSources = dropDownSelectedSources ?? selectedSources;
      const convertedSelectedSources = createSourceModulePayloadMapping(finalSelectedSources);
      const newPayload: any = {
        ...resultsState.decryptedPayload,
        source: convertedSelectedSources
      };
      if (newPayload?.entity_category) {
        delete newPayload.entity_category;
      }

      await handleApplicationsSearch(newPayload);
    },
    [selectedSources, entityValue]
  );

  const applyNewEntityText = useCallback(async (option: any) => {
    const entityList: any = [];
    localEntityTextOptions.forEach((item: any) => {
      if (item.value === option.value) {
        entityList.push({
          label: startCase(option.value).replace(/_/g, ''),
          value: option.value,
          selected: true
        });
        setLocalEntityValue(option.value);
      } else {
        entityList.push({
          label: startCase(item.value).replace(/_/g, ''),
          value: item.value,
          selected: false
        });
      }
    });
    setLocalEntityTextOptions(entityList);

    const newPayload: any = {
      ...resultsState.decryptedPayload,
      search_term: option.value
    };
    if (newPayload.entity_category) {
      delete newPayload.entity_category;
    }

    await handleApplicationsSearch(newPayload);
  }, []);

  const searchApplications = (searchText: string) => {
    const query = searchText?.trim()?.toLowerCase();

    if (!query || query?.length < 1) {
      return applicationList;
    }

    return applicationList.filter((application: any) => {
      const stringValuesMatched = [
        'identifier',
        'groupTitle',
        'sponsor_name',
        'title',
        'approvalPathway',
        'approval_year'
      ].some(field => application[field]?.toLowerCase().includes(query));

      if (stringValuesMatched) {
        return true;
      }

      if (application?.formulation?.toString().toLowerCase().includes(query)) {
        return true;
      }

      if (application?.route?.toString().toLowerCase().includes(query)) {
        return true;
      }

      return false;
    });
  };

  const handleSearch = (searchText: string) => {
    setLocalApplicationList(searchApplications(searchText));
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 300), [applicationList]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText: string = e.target.value;
    setLocalSearchQuery(searchText);
    debouncedHandleSearch(searchText);
  };

  const handleViewToggle = (applicationsViewType: string) => {
    const newValue = applicationsViewType === 'grid' ? 'grid' : 'list';
    const viewOpts = viewOptions.map((opt: any) => ({
      ...opt,
      selected: newValue === opt.id
    }));
    setViewOptions(viewOpts);
    setCurrentViewType(newValue);
  };

  const fetchS3url = async (path: string, bucket: string) => {
    const record: any = {};
    record.s3_path = decodeURIComponent(path);
    record.s3_bucket = bucket;
    try {
      const response = await getPdfResource({
        ...record
      });
      if (response?.data?.status === 200) {
        return response?.data?.body.s3_url;
      }
      return '';
    } catch (error) {
      return '';
    }
  };

  const gets3Link = async () => {
    try {
      const applData = [...resultsState.comparisonApplications];
      await Promise.all(
        applData.map(async data => {
          // eslint-disable-next-line no-param-reassign
          data.url = await fetchS3url(data.s3_label_url, data.s3_bucket);
        })
      );

      setApplicationData(applData);
    } catch (error) {
      console.error('Error fetching S3 URLs:', error);
    }
  };

  return (
    <Drawer
      open={open}
      anchor='bottom'
      onClose={() => onClose()}
      PaperProps={{
        style: {
          height: '98vh',
          width: '100vw',
          backgroundColor: '#fff',
          maxWidth: 'none',
          maxHeight: 'none',
          borderRadius: '8px 8px 0px 0px',
          transition: 'all 1s ease-in-out'
        }
      }}>
      <Stack direction='row' sx={{ bgcolor: 'primary.600', alignSelf: 'stretch', p: '12px 24px' }}>
        <Stack spacing={1} sx={{ flexGrow: 1, textAlign: 'center', alignSelf: 'center' }}>
          <Typography variant='h3' fontWeight='bold' sx={{ color: 'white.main' }}>
            {resultsState?.labelComparisonSelectionMethod?.value === COMPARE_LABEL_TYPES.ENHANCED
              ? 'Enhanced Comparison'
              : 'View Latest Labels'}
          </Typography>
        </Stack>
        <IconButton
          aria-label='delete'
          onClick={onClose}
          size='small'
          sx={{ position: 'absolute', display: 'flex', left: '97%', top: '1%' }}>
          <HighlightOffOutlinedIcon
            fontSize='inherit'
            sx={{ height: '24px', width: '24px', color: 'white.main' }}
          />
        </IconButton>
      </Stack>
      <Box mt='10px'>
        <Stack direction='row' justifyContent='space-between' px='28px'>
          <Stack direction='row'>
            <TextField
              sx={{
                '& .MuiInputBase-root': { borderRadius: '32px', height: '30px', width: '300px' }
              }}
              placeholder='Find applications'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <MagnifyingGlassIcon sx={{ height: '14px', width: '14px' }} />
                  </InputAdornment>
                )
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                maxLength: 75
              }}
              onChange={handleSearchChange}
              value={localSearchQuery}
            />

            <Button
              endIcon={<ArrowDownIcon sx={{ height: '16px', width: '16px', color: 'gray.700' }} />}
              sx={{
                fontSize: '14px',
                textTransform: 'none',
                fontWeight: '600',
                ml: '8px',
                color: 'gray.700'
              }}
              onClick={handleOpenMenu}>
              <Badge
                color='primary'
                variant='dot'
                invisible={!isAnyOptionSelected}
                sx={{
                  '& .MuiBadge-badge': {
                    height: '6px',
                    minWidth: '6px',
                    top: 4
                  }
                }}>
                Sort & View
              </Badge>
            </Button>
            <RoundedMenu anchorEl={anchorEl} open={isMenuOpen} onClose={handleCloseMenu}>
              <DropDownButton
                label='Sort by'
                type='action'
                options={sortOptions}
                onOptionSelect={sortBy}
                tooltip='Select to sort items'
                isMenuItem
                MenuIcon={SortIconVariant}
                showBadge={selectedSort?.selected && selectedSort?.id !== 'default'}
                handleParentMenuClose={handleCloseMenu}
              />
              <MenuItemWithSubMenu
                MenuItemIcon={
                  <Badge
                    color='primary'
                    variant='dot'
                    invisible={currentViewType === 'grid'}
                    sx={{
                      '& .MuiBadge-badge': {
                        height: '6px',
                        minWidth: '6px',
                        top: -2
                      }
                    }}>
                    <ListViewIcon sx={{ height: '16px', width: '16px', color: 'gray.700' }} />
                  </Badge>
                }
                MenuItemText='View by'>
                <MenuWithTickOptions
                  options={viewOptions}
                  onOptionSelect={(opt: any) => {
                    handleViewToggle(opt?.id ?? 'grid');
                    handleCloseMenu();
                  }}
                  isNestedMenu
                />
              </MenuItemWithSubMenu>
            </RoundedMenu>
          </Stack>

          <Button
            sx={{
              height: '30px',
              padding: '15px 24px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '16px',
              backgroundColor: 'primary.650',
              color: 'white.main',
              textTransform: 'none',
              fontSize: '14px',
              fontWeight: 700,
              '&:hover': {
                backgroundColor: 'white.main',
                border: 1,
                borderColor: 'primary.main',
                borderStyle: 'solid',
                color: 'primary.600'
              },
              '&:disabled': {
                backgroundColor: 'gray.550',
                color: 'gray.background'
              }
            }}
            onClick={async () => {
              if (
                resultsState?.labelComparisonSelectionMethod?.value === COMPARE_LABEL_TYPES.VIEW
              ) {
                dispatch({
                  type: Actions.SET_ALERT,
                  value: {
                    status: true,
                    message: 'Please wait while we prepare the comparison',
                    duration: 3000,
                    color: 'warning'
                  }
                });
                await gets3Link();
                setOpenComparisonView(true);
              } else {
                setOpenComparisonView(true);
              }
            }}>
            Next: Select labels
          </Button>
        </Stack>
        <Divider sx={{ my: '8px', mx: '28px' }} />
      </Box>
      <Stack direction='row' justifyContent='space-between' px='28px'>
        {showSecondaryNavigation && (
          <Stack direction='row'>
            <Stack direction='row' alignItems='center'>
              <SourceDropDown
                selectedSources={selectedSources}
                handleChange={handleSourceChange}
                options={applicationSourceDropdown}
                anchorEl={sourceDropDownAnchorEl}
                setAnchorEl={setSourceDropDownAnchorEl}
                type={SOURCE_DROPDOWN_TYPES.APPLICATIONS_VIEW}
                miniminumOneSelected
                showApplyButton
                makeSearch={(dropDownSelectedSources: any) => {
                  makeNewSearch(dropDownSelectedSources);
                }}
              />
            </Stack>
            <Divider orientation='vertical' />
            <DropDownButton
              label={
                entityCategory ||
                `${resultsState?.entities?.category_label} (${applicationList.length})` ||
                'Category'
              }
              options={entityCategoryOptions}
              onOptionSelect={applyCategoryEntity}
              tooltip='Switch category'
            />
            <Divider orientation='vertical' />
            <DropDownButton
              label={
                startCase(localEntityValue)?.replace(/_/g, '') ||
                startCase(resultsState?.entities?.search_term)?.replace(/_/g, '') ||
                'Entity Text'
              }
              options={localEntityTextOptions}
              onOptionSelect={applyNewEntityText}
              tooltip='Switch query'
            />
          </Stack>
        )}
        <Box
          sx={{
            borderRadius: '6px',
            p: '2px 8px',
            backgroundColor: 'gray.100',
            height: '21px'
          }}>
          <Typography
            sx={{
              color: 'gray.700',
              fontFamily: 'Mulish',
              fontSize: '12px'
            }}>
            Showing <strong>{applicationListToShow.length}</strong> of{' '}
            <strong>{applicationList.length} applications</strong>
          </Typography>
        </Box>
      </Stack>

      {!openComparisonView && localApplicationList && (
        <Stack mt={1.25}>
          {currentViewType === 'grid' && (
            <VirtualizedGrid
              applications={applicationListToShow}
              hideApplication={[]}
              labelComparisonSelection
              handleLabelSection={handleLabelSection}
              handleCompareView={handleCompareView}
              comparisonApplications={comparisonApplications}
              labelComparisonLimit={resultsState?.labelComparisonSelectionMethod?.limit ?? 0}
              applyUseWindowScroll={false}
              customHeight='calc(100vh - 185px)'
            />
          )}
          {currentViewType === 'list' && (
            <Stack sx={{ height: 'calc(100vh - 185px)' }}>
              <VivproDatagrid
                rows={applicationListToShow as any}
                columnsMapping={columnDefs}
                rowId='identifier'
                loading={false}
                csvFileName='Export'
                isCheckboxSelectionEnabled
                handleCheckBoxSelection={(newRowSelectionModel: any) => {
                  const newSelectedApplications = newRowSelectionModel.map(
                    (app: any) => app.identifier
                  );

                  const alreadySelectedApplications = resultsState?.comparisonApplications.map(
                    (app: any) => app.identifier
                  );

                  const [removed] = difference(
                    alreadySelectedApplications,
                    newSelectedApplications
                  );
                  const [added] = difference(newSelectedApplications, alreadySelectedApplications);

                  if (removed) {
                    const [newComparisonApplication] =
                      resultsState?.comparisonApplications.filter(
                        (app: any) => app.identifier === removed
                      ) || [];

                    handleCompareView(newComparisonApplication, false);
                  }

                  if (added) {
                    const [newComparisonApplication] =
                      applicationListToShow?.filter((app: any) => app.identifier === added) || [];
                    handleCompareView(newComparisonApplication, true);
                  }
                }}
                rowSelectionModel={resultsState.comparisonApplications.map(
                  (app: any) => app.identifier
                )}
                dataGridRowSelectionLimit={resultsState?.labelComparisonSelectionMethod?.limit ?? 0}
              />
            </Stack>
          )}
        </Stack>
      )}

      {openComparisonView &&
        resultsState?.labelComparisonSelectionMethod?.value === COMPARE_LABEL_TYPES.VIEW && (
          <PDFComparisonDialog
            sources={sources}
            open={openComparisonView}
            keywords={searchTerm}
            pdfData={applicationData}
            onSwap={({ message, currentIndex, targetIndex }: any) => {
              dispatch({
                type: Actions.SET_ALERT,
                value: { status: true, message, color: 'success', duration: 4000 }
              });
              const comparisonApplicationsNewSeq = [...resultsState.comparisonApplications];
              const temp = comparisonApplicationsNewSeq[currentIndex];
              comparisonApplicationsNewSeq[currentIndex] =
                comparisonApplicationsNewSeq[targetIndex];
              comparisonApplicationsNewSeq[targetIndex] = temp;
              resultsDispatch({
                type: ResultActions.SET_COMPARISON_APPLICATIONS,
                value: comparisonApplicationsNewSeq
              });
            }}
            onClose={() => setOpenComparisonView(false)}
          />
        )}

      {openComparisonView &&
        resultsState?.labelComparisonSelectionMethod?.value === COMPARE_LABEL_TYPES.ENHANCED && (
          <ComparisonDialogComp
            open={openComparisonView}
            onClose={() => setOpenComparisonView(false)}
            applications={resultsState?.comparisonApplications?.map(item => ({
              source: item?.source,
              application_type: item.applicationType ?? '',
              application_number: item.identifier,
              application_name: item?.title
            }))}
            mode={mode}
            showBackButton
          />
        )}
    </Drawer>
  );
};

export default memo(SelectionView);
