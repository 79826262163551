import { useCallback, useMemo, useState, useContext, memo } from 'react';

import {
  Box,
  Stack,
  Typography,
  IconButton,
  Tooltip,
  Drawer,
  Divider,
  Button
} from '@mui/material';

import Close from '@mui/icons-material/Close';

import SearchIcon from '../../assets/svgs/Icons/SearchIcon';
import SearchBarAutoComplete from '../Home/components/SearchBarAutoComplete';
import SourceDropDown from '../Home/components/SourceDropDown';
import { SourceDropdown } from '../Home/types';
import useSearchSuggestions from '../Home/hooks/useSearchSuggestions';

import styles from './styles/SearchBarStyles';
import searchBarStyles from '../Home/styles/SearchBar.styles';

import {
  createSourceModulePayloadMapping,
  getCompareSourceDropdown,
  getDefaultCompareSourceDropdown
} from '../Home/utils';
import RESULT_VIEW_TYPES from '../SearchResults/components/constants';
import { apiDataToCardProps, COMPARE_LABEL_METHODS } from '../SearchResults/constants';

import { getGenericSearch } from '../../api/pages/ResultsPage';

import ResultsStore from '../../store/SearchResults';
import ResultActions from '../../store/SearchResults/actions';
import SelectionView from '../ResultsPage/components/SelectionView';
import EntitySelectionWindow from '../SearchResults/components/EntitySelectionWindow';
import entityHandler from '../SearchResults/utils/entityHandler';

const ComparisonSearchBox = ({ modalOpen, setModalOpen }: any) => {
  const { resultsDispatch, resultsState } = useContext(ResultsStore);
  const [selectedSources, setSelectedSources] = useState<SourceDropdown[]>(() =>
    getDefaultCompareSourceDropdown()
  );
  const [sourceDropDownAnchorEl, setSourceDropDownAnchorEl] = useState<null | HTMLElement>(null);
  const [searchText, setSearchText] = useState<string>('');
  const {
    searchSuggestions,
    isSuggestionsLoading,
    setIsSearchOpen,
    handleKeyPress,
    clearSearchSuggestions,
    isSearchOpen
  } = useSearchSuggestions(selectedSources);
  const [viewType, setViewType] = useState(RESULT_VIEW_TYPES.APPLICATION);
  const [entities, setEntities] = useState<any[]>([]);

  const handleAutoCompleteOpen = useCallback(() => setIsSearchOpen(true), [setIsSearchOpen]);

  const handleAutoCompleteClose = useCallback(() => setIsSearchOpen(false), [setIsSearchOpen]);

  const handleSourceChange = useCallback(
    (values: SourceDropdown[]) => {
      setSelectedSources(values);
    },
    [setSelectedSources]
  );

  const compareSourceDropDown = useMemo(() => getCompareSourceDropdown(), []);

  const handleApplicationsSearch = async (payload: any) => {
    const result = await getGenericSearch(payload);

    const resultApplications = result?.data?.body?.result || {};

    const currentViewType = result?.data?.body?.view_type ?? RESULT_VIEW_TYPES.APPLICATION;

    const viewTypeMetadata = result?.data?.body?.view_type_metadata ?? {};

    resultsDispatch({ type: ResultActions.SET_DECRYPTED_PAYLOAD, value: payload });
    resultsDispatch({ type: ResultActions.SET_DECRYPTED_SOURCE, value: payload.source });
    resultsDispatch({ type: ResultActions.SET_VIEW_TYPE, value: currentViewType });

    resultsDispatch({
      type: ResultActions.SET_ENTITY_LIST,
      value: viewTypeMetadata
    });

    if (currentViewType === RESULT_VIEW_TYPES.ENTITY) {
      const resultEntities = result?.data?.body?.entities ?? [];
      setEntities(entityHandler(resultEntities));
    }

    resultsDispatch({
      type: ResultActions.SET_SEARCH_SUGGESTIONS,
      value: result?.data?.body?.alternate_results ?? []
    });

    let apps: any = [];
    const resultSources: any = [];
    Object.keys(resultApplications).forEach(source => {
      resultSources.push(source);
      if (apiDataToCardProps[source]) {
        const sourceApps = resultApplications[source].results.map((appData: any) =>
          apiDataToCardProps[source]?.(appData)
        );
        apps = [...apps, ...sourceApps];
      }
    });

    resultsDispatch({
      type: ResultActions.SET_APPLICATION_RESULTS,
      value: apps
    });

    setViewType(currentViewType);
  };

  const entityClick = async (entityText: string) => {
    const convertedSelectedSources = createSourceModulePayloadMapping(selectedSources);
    const payload = {
      query: searchText,
      search_term: entityText,
      source: convertedSelectedSources,
      view_type: RESULT_VIEW_TYPES.APPLICATION
    };

    await handleApplicationsSearch(payload);
  };

  const handleSearch = async (value: string = searchText) => {
    const convertedSelectedSources = createSourceModulePayloadMapping(selectedSources);
    const payload = {
      query: value,
      source: convertedSelectedSources,
      view_type: RESULT_VIEW_TYPES.APPLICATION
    };

    await handleApplicationsSearch(payload);

    const selectedComparisonMethod = COMPARE_LABEL_METHODS.enhancedComparison;
    resultsDispatch({
      type: ResultActions.SET_LABEL_COMPARISON_SELECTION_METHOD,
      value: {
        limit: selectedComparisonMethod.limit,
        value: selectedComparisonMethod.value
      }
    });

    resultsDispatch({
      type: ResultActions.SET_LABEL_COMPARISON_SELECTION,
      value: true
    });
  };

  return (
    <>
      <Drawer
        sx={{
          '& >.MuiDrawer-paper': {
            height: '60vh',
            borderRadius: '16px',
            paddingX: '24px',
            paddingY: '32px',
            position: 'absolute'
          }
        }}
        anchor='bottom'
        onClose={() => setModalOpen('')}
        open={!!modalOpen}>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'stretch'
            }}>
            <Typography
              sx={{
                flexGrow: 1,
                textAlign: 'center',
                alignSelf: 'center',
                fontWeight: '700',
                fontSize: '20px',
                color: 'gray.800',
                textTransform: 'capitalize'
              }}>
              Label Comparison
            </Typography>
            <Tooltip title='Close'>
              <IconButton
                onClick={() => setModalOpen('')}
                sx={{ position: 'absolute', right: '24px', top: '26px' }}>
                <Close />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display='flex' flexDirection='row' justifyContent='center'>
            <Divider
              sx={{
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'gray.400',
                borderRadius: '2px',
                mt: 1,
                width: '64px'
              }}
            />
          </Box>
          <Stack spacing={1}>
            <form
              onSubmit={event => {
                event.preventDefault();
                handleSearch();
              }}>
              <Box sx={styles.searchBar}>
                <Stack direction='row' alignItems='center'>
                  <SourceDropDown
                    selectedSources={selectedSources}
                    handleChange={handleSourceChange}
                    options={compareSourceDropDown}
                    anchorEl={sourceDropDownAnchorEl}
                    setAnchorEl={setSourceDropDownAnchorEl}
                  />
                  <Divider
                    orientation='vertical'
                    flexItem
                    sx={{ mb: '12px', mt: '12px', backgroundColor: 'gray.200' }}
                  />
                  <Box flex={1}>
                    <SearchBarAutoComplete
                      id='comparsion-search-input'
                      value={searchText}
                      label='Search for drug name here!'
                      disableSearch={selectedSources.length === 0}
                      options={searchSuggestions}
                      loading={isSuggestionsLoading}
                      onOpen={handleAutoCompleteOpen}
                      open={isSearchOpen}
                      onClose={handleAutoCompleteClose}
                      filterOptions={undefined}
                      onInputChange={handleKeyPress}
                      handleSearch={handleSearch}
                      setSearch={value => setSearchText(value)}
                      clearSearchSuggestions={() => {
                        clearSearchSuggestions();
                      }}
                    />
                  </Box>
                  <Button
                    type='submit'
                    size='small'
                    startIcon={<SearchIcon style={{ fontSize: 12 }} />}
                    sx={searchBarStyles.searchButton}>
                    Search
                  </Button>
                </Stack>
              </Box>
            </form>
          </Stack>
        </Box>
      </Drawer>
      {viewType === RESULT_VIEW_TYPES.APPLICATION && resultsState?.labelComparisonSelection && (
        <SelectionView
          open={resultsState?.labelComparisonSelection}
          onClose={() => {
            resultsDispatch({
              type: ResultActions.SET_LABEL_COMPARISON_SELECTION,
              value: false
            });
          }}
          applicationList={resultsState?.applicationResults}
          sources={selectedSources}
          comparisonApplications={resultsState?.comparisonApplications}
          showSecondaryNavigation
        />
      )}
      {viewType === RESULT_VIEW_TYPES.ENTITY && (
        <EntitySelectionWindow
          openEntityPopup={viewType === RESULT_VIEW_TYPES.ENTITY}
          setOpenEntityPopup={() => setViewType('')}
          entityList={entities}
          handleEntityClick={entityClick}
          triggeredFromPopup
        />
      )}
    </>
  );
};

export default memo(ComparisonSearchBox);
