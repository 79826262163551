import React from 'react';

// Common Styles
const commonBackgroundGreen = {
  background: '#33B187',
  color: 'white.main'
};

const commonBorderBottom = {
  borderBottom: '1px solid #ccc'
};

const commonFontSize16Weight800 = {
  fontSize: '16px',
  fontWeight: '800'
};

const commonCompareAfter = {
  '&:after': {
    position: 'absolute',
    right: '0px',
    bottom: '0px',
    padding: '0 5px',
    backgroundColor: 'rgb(247, 247, 247)'
  }
};

const commonButtonStyle = {
  padding: '3px 20px',
  fontSize: '1.7vh',
  fontWeight: 'bold',
  border: '2px solid #e3f1ff',
  boxShadow: '0 0 4px rgb(220, 220, 220,0.5)',
  borderRadius: '20px',
  backgroundColor: 'primary.700',
  color: '#FFFFFF',
  cursor: 'pointer',
  margin: '0 auto',
  display: 'flex',
  width: 'fit-content'
};

// Specific Styles
const styles = {
  selectLabel: {
    fontFamily: 'Mulish',
    fontSize: '15px',
    color: 'gray.900',
    fontWeight: 700
  },
  loadingContainer: {
    width: '100vw',
    height: '100vh'
  },
  selectLabelC: {
    ...commonBackgroundGreen,
    borderRadius: '10px 10px 0px 0px',
    marginBottom: 'auto',
    fontSize: '2.3vh',
    padding: '13px 10px'
  },
  compareBox: {
    backgroundColor: 'white.main',
    height: '90%',
    position: 'relative',
    border: '1px solid #EEE'
  },
  labelContainer: {
    height: '58px',
    display: 'flex',
    cursor: 'pointer',
    gap: '12px'
  },
  labelContainer__selected: {
    cursor: 'pointer',
    display: 'flex',
    height: '58px',
    gap: '12px'
  },
  compareText: {
    color: '#757575',
    fontFamily: 'Mulish',
    fontSize: '13px',
    fontWeight: 400,
    textAlign: 'justify',
    display: 'flex',
    lineHeight: '1.5em',
    minHeight: '1em',
    height: '3em',
    overflow: 'hidden',
    position: 'relative',
    ...commonCompareAfter
  },
  compareSubText: {
    textAlign: 'justify',
    display: 'flex',
    fontSize: 'initial',
    margin: '0px 16px 0px 16px',
    lineHeight: '1.5em',
    height: '2em',
    overflow: 'hidden',
    position: 'relative',
    ...commonCompareAfter
  },
  compareIcon: {
    width: '33px',
    paddingRight: '10px'
  },
  compareIFrame: {
    height: '100%',
    width: '100%'
  },
  centerBall: {
    width: '28px',
    position: 'absolute',
    marginTop: '-14px',
    right: '50%'
  } as React.CSSProperties,
  checkboxIcon: {
    margin: 'unset',
    width: '100%',
    justifyContent: 'center'
  },
  labelTitle: {
    fontSize: '1.4vh',
    fontWeight: 500,
    paddingLeft: '5px',
    margin: 'auto'
  },
  labelTitle2: {
    color: 'white.main',
    fontWeight: 600
  },
  labelDate: {
    ...commonFontSize16Weight800,
    color: '#212121',
    fontFamily: 'Mulish',
    fontSize: '13px',
    fontWeight: 700
  },
  labelDateGreen: {
    ...commonFontSize16Weight800,
    color: '#33B187'
  },
  labelSup: {
    color: '#212121',
    fontFamily: 'Mulish',
    fontSize: '13px',
    fontWeight: 700
  },
  labelDescription: {
    color: '#757575',
    fontFamily: 'Mulish',
    fontSize: '13px',
    fontWeight: 400
  },
  LabelTitle: {
    color: '#1C7D5D',
    fontWeight: '800',
    fontSize: '3.5vh',
    fontFamily: 'Constantia',
    paddingTop: '32px'
  },
  labelSupGreen: {
    fontSize: '16px',
    fontWeight: '570',
    color: '#33B187'
  },
  labelChangeGreen: {
    ...commonFontSize16Weight800,
    padding: '10px 0px',
    color: '#33B187'
  },
  labelChange: {
    ...commonFontSize16Weight800,
    padding: '10px 0px'
  },
  mostRecent: {
    margin: '5px',
    padding: '3px 20px',
    fontSize: '1.25vh',
    fontWeight: 'bold',
    border: '2px solid #e3f1ff',
    boxShadow: '0 0 4px rgb(220, 220, 220,0.5)',
    borderRadius: '20px',
    backgroundColor: '#f7fbff',
    color: 'green.accept',
    cursor: 'pointer'
  },
  resize: {
    fontSize: 1.85,
    marginLeft: 1,
    width: '100%'
  },
  compareL: {
    ...commonButtonStyle,
    height: '30px'
  },
  twoBox: {
    background: 'white.main',
    height: '65vh',
    overflowY: 'auto'
  },
  StyledErrorTypography: {
    fontSize: '2vh',
    padding: '15px',
    color: 'black.main',
    borderRadius: '10px',
    backgroundColor: 'blue.alice',
    fontWeight: '700'
  },
  compareN: {
    ...commonButtonStyle,
    height: '30px',
    margin: '10px auto'
  },
  productCompareButton: {
    ...commonButtonStyle,
    backgroundColor: 'primary.700',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'primary.700'
    },
    '&:disabled': {
      backgroundColor: 'gray.light'
    }
  },
  compareLine: {
    display: 'inline-flex',
    borderTop: '1px solid #a2a2a2',
    marginBottom: '20px',
    width: '46%'
  },
  dialog2: {
    height: '60vh',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed'
  },
  labelLoadErrorMessage: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  tradeNameText: {
    display: 'flex',
    p: '5px',
    flexGrow: 1,
    justifyContent: 'center',
    fontSize: '2.4vh',
    fontWeight: 500,
    color: 'primary.main'
  },
  titleTab: {
    color: 'gray.800',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'Mulish',
    borderRadius: '4px 4px 0px 0px',
    textAlign: 'left'
  },
  container: {
    mt: '3.1em',
    bgcolor: '#fff',
    borderRadius: '4px 16px 16px 16px',
    border: '2px solid #289D78',
    mr: '24px'
  },
  title: {
    color: 'primary.avatar',
    fontWeight: 'bold'
  },
  followUpQuestionsStyle: {
    bgcolor: '#314f00',
    p: 1,
    borderRadius: '16px 4px 16px 16px',
    color: '#e9eddc',
    fontSize: 12,
    fontWeight: 'bold'
  },
  followUpLoader: {
    bgcolor: 'primary.backgroundLight',
    p: 1,
    borderRadius: '16px 4px 16px 16px',
    color: '#002108',
    fontSize: 12
  },
  expandIcon: {
    color: 'primary.dark',
    bgcolor: 'primary.backgroundLightVariant',
    borderRadius: '50%'
  }
};

export default styles;
