import React, { useEffect, useMemo, useState } from 'react';

import {
  Avatar,
  Chip,
  Tooltip,
  Divider,
  Radio,
  Stack,
  Button,
  InputAdornment,
  TextField,
  Skeleton,
  Box,
  Typography,
  Badge
} from '@mui/material';

import { flattenDeep } from 'lodash';

import * as Select from '../CustomComponents/Selects';
import { SECTION_LABELS } from './const';

import comparisonClasses from './styles/LabelSelection.style';

import emaLogo from '../../Images/ema-logo.png';
import euLogo from '../../Images/eu-logo.png';
import { sourceFlagMapping, sourceFlagMappingTooltip } from '../../pages/SearchResults/constants';
import { SortIconVariant, MagnifyingGlassIcon } from '../../assets/svgs/Icons';

// assets

const { VivproSelect }: any = Select; // Work around for usage error.

interface LabelListProps {
  selectedApplication: any;
  isLoading: boolean;
  labelList: Array<any> | undefined;
  selectedLabel: any;
  errorMessage: string | undefined;
  // eslint-disable-next-line no-unused-vars
  onLabelSelect: (label: any, section: string) => void;
}

const LabelList: React.FC<LabelListProps> = ({
  selectedApplication,
  isLoading,
  labelList,
  selectedLabel,
  errorMessage,
  onLabelSelect
}) => {
  const FlagComponent = sourceFlagMapping[selectedApplication?.source?.toLowerCase()];
  const flagTooltipTitle = sourceFlagMappingTooltip[selectedApplication?.source?.toLowerCase()];
  const [oldestTONewest, setOldestTONewest] = useState(false);
  const [cartonFilter, setCartonFilter] = useState({
    filterApplied: false,
    count: 0
  });

  const [localSearchQuery, setLocalSearchQuery] = useState('');

  useEffect(() => {
    if (labelList) {
      const sortedLabel = JSON.parse(JSON.stringify(labelList));
      setCartonFilter({
        ...cartonFilter,
        count: sortedLabel.reduce((count: number, obj: any) => {
          return count + (obj.label_type === 'carton' ? 1 : 0);
        }, 0)
      });
    }
  }, [labelList]);

  const handleSortBy = () => {
    setOldestTONewest((prev: boolean) => {
      return !prev;
    });
  };

  const handleCartonFilterBy = () => {
    setCartonFilter({
      ...cartonFilter,
      filterApplied: !cartonFilter.filterApplied
    });
  };

  const getComparisonText = () => {
    if (selectedApplication?.source?.toLowerCase() === 'us') {
      return selectedApplication?.application_type;
    }
    if (selectedApplication?.source?.toLowerCase() === 'ca') {
      return 'CA ';
    }
    if (selectedApplication?.source?.toLowerCase() === 'hpra') {
      return 'HPRA ';
    }
    if (selectedApplication?.source?.toLowerCase() === 'jp') {
      return 'JP ';
    }
    if (selectedApplication?.source?.toLowerCase() === 'hma') {
      return 'HMA ';
    }
    return 'EMEA-H-C-';
  };

  const labelListToShow = useMemo(() => {
    if (!labelList) return [];

    const query = localSearchQuery?.trim()?.toLowerCase();

    return labelList
      .filter(label => {
        // Apply carton filter
        if (cartonFilter.filterApplied && label.label_type === 'carton') {
          return false;
        }

        // Apply search filter
        if (query) {
          const matchesQuery = [
            'approval_date',
            'description',
            'submission_type',
            'submission_num'
          ].some(field => label?.[field]?.toLowerCase().includes(query));

          if (matchesQuery) {
            return true;
          }

          const changes = flattenDeep(label?.change)?.toString()?.toLowerCase();

          if (changes?.includes(query)) {
            return true;
          }

          return false;
        }

        return true;
      })
      .sort((a: any, b: any) => {
        return oldestTONewest
          ? new Date(a.approval_date).getTime() - new Date(b.approval_date).getTime()
          : new Date(b.approval_date).getTime() - new Date(a.approval_date).getTime();
      });
  }, [labelList, oldestTONewest, cartonFilter, localSearchQuery]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchQuery(e.target.value);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderRadius: '8px 8px 0px 0px',
          padding: '10px 16px',
          backgroundColor: '#EEE',
          height: '48px'
        }}>
        <Stack direction='row' alignItems='center' spacing={0.5}>
          <Typography sx={comparisonClasses.selectLabel}>
            {selectedApplication?.application_name &&
            selectedApplication.application_name?.indexOf('-') !== -1
              ? selectedApplication.application_name.substring(
                  selectedApplication.application_name.indexOf('-') + 1
                )
              : selectedApplication.application_name}{' '}
            - {selectedApplication?.application_number || selectedApplication?.product_number}
          </Typography>
          <Tooltip
            title={flagTooltipTitle}
            disableInteractive
            disableFocusListener
            disableTouchListener>
            <FlagComponent sx={{ width: '18px', height: '13px' }} />
          </Tooltip>
        </Stack>

        <Box width='20%' display='flex' flexDirection='row' justifyContent='flex-end'>
          {selectedApplication?.source.toLowerCase() === 'us' && cartonFilter.count !== 0 && (
            <Tooltip
              title={
                cartonFilter.filterApplied
                  ? 'Show all carton labels in the list'
                  : 'Hide all carton labels in the list'
              }>
              <Box
                display='flex'
                alignItems='center'
                onClick={handleCartonFilterBy}
                sx={{ cursor: 'pointer' }}>
                <Typography
                  sx={{
                    fontSize: 12,
                    color: 'gray.700',
                    textDecoration: 'underline'
                  }}>
                  {cartonFilter.filterApplied
                    ? `Show Cartons (${cartonFilter.count})`
                    : `Hide Cartons (${cartonFilter.count})`}
                </Typography>
              </Box>
            </Tooltip>
          )}
        </Box>
      </Box>

      <Box sx={comparisonClasses.compareBox}>
        {isLoading ? (
          <Box p={1}>
            <Skeleton variant='text' width='30%' />
            <Skeleton variant='rectangular' width='90%' height={150} />
            <Skeleton variant='text' width='30%' />
            <Skeleton variant='rectangular' width='90%' height={150} />
            <Skeleton variant='text' width='30%' />
            <Skeleton variant='rectangular' width='90%' height={150} />
          </Box>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {errorMessage ? (
              <Box sx={comparisonClasses.labelLoadErrorMessage}>{errorMessage}</Box>
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  sx={{
                    padding: '10px 16px',
                    backgroundColor: 'gray.50',
                    borderBottom: '1px solid',
                    borderColor: 'gray.200'
                  }}>
                  <TextField
                    sx={{
                      '& .MuiInputBase-root': {
                        borderRadius: '32px',
                        height: '28px',
                        width: '260px',
                        backgroundColor: 'white.main'
                      }
                    }}
                    placeholder='Find label'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <MagnifyingGlassIcon sx={{ height: '14px', width: '14px' }} />
                        </InputAdornment>
                      )
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{
                      maxLength: 50
                    }}
                    onChange={handleSearchChange}
                    value={localSearchQuery}
                  />
                  <Tooltip
                    title={`Sort by ${oldestTONewest ? 'Newest to Oldest' : 'Oldest to Newest'}`}>
                    <Button onClick={handleSortBy}>
                      <Badge variant='dot' invisible={!oldestTONewest} color={'primary' as any}>
                        <SortIconVariant
                          sx={{ height: '14px', width: '14px', color: 'gray.600' }}
                        />
                      </Badge>
                    </Button>
                  </Tooltip>
                </Stack>
                <Box
                  sx={{
                    px: '32px',
                    pt: '8px',
                    overflowY: 'auto',
                    height: '90%',
                    position: 'relative',
                    minHeight: 'auto'
                  }}>
                  {labelListToShow &&
                    labelListToShow.map((item: any) => (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <React.Fragment
                        key={`${item.submission_type}-${item.approval_date}-${item.submission_num}`}>
                        {item.label.sections && item.label.sections.length > 0 && (
                          <Box
                            sx={
                              selectedLabel?.submissionType === item.submission_type &&
                              selectedLabel?.submissionNum === item.submission_num &&
                              selectedLabel?.description === item.description &&
                              selectedLabel?.approvalDate === item.approval_date
                                ? comparisonClasses.labelContainer__selected
                                : comparisonClasses.labelContainer
                            }>
                            <Box>
                              <Radio
                                size='small'
                                checked={
                                  selectedLabel?.submissionType === item.submission_type &&
                                  selectedLabel?.submissionNum === item.submission_num &&
                                  selectedLabel?.description === item.description &&
                                  selectedLabel?.approvalDate === item.approval_date
                                }
                                sx={{
                                  '&.Mui-checked': {
                                    color: 'primary.600'
                                  }
                                }}
                                onClick={() => {
                                  onLabelSelect(item, '');
                                }}
                              />
                            </Box>
                            <Box
                              pt={1}
                              onClick={() => {
                                onLabelSelect(item, '');
                              }}>
                              <Box display='flex' alignItems='center'>
                                {selectedApplication?.source.toLowerCase() === 'eu' && (
                                  <Tooltip title={`Source: ${item.source}`}>
                                    <Chip
                                      avatar={
                                        <Avatar
                                          alt='EMA'
                                          src={item.source === 'EMA' ? emaLogo : euLogo}
                                        />
                                      }
                                      label={item.source}
                                      variant='filled'
                                      sx={{
                                        backgroundColor: 'blue.main',
                                        color: 'white.main',
                                        m: 1,
                                        textTransform: 'none !important'
                                      }}
                                    />
                                  </Tooltip>
                                )}
                                <Typography sx={comparisonClasses.labelDate}>
                                  {item.approval_date}
                                </Typography>
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  sx={{
                                    mx: '8px',
                                    color: 'gray.200'
                                  }}
                                />
                                <Typography sx={comparisonClasses.labelSup}>
                                  {`${item.submission_type}${
                                    item.submission_num ? `-${item.submission_num}` : ''
                                  }`}
                                  &nbsp;
                                </Typography>
                                <Typography sx={comparisonClasses.labelSup}>
                                  {item.description}
                                </Typography>
                                {selectedApplication?.source.toLowerCase() === 'us' &&
                                  item?.label_type &&
                                  item?.label_type === 'carton' && (
                                    <Box sx={{ textDecoration: 'none' }}>
                                      <Chip
                                        size='small'
                                        label='CARTON'
                                        sx={{
                                          ml: 1,
                                          height: 13,
                                          fontSize: 10,
                                          borderRadius: 1,
                                          fontWeight: 800,
                                          '& span': {
                                            px: 0.5
                                          },
                                          color: 'red.main'
                                        }}
                                      />
                                    </Box>
                                  )}
                              </Box>
                              <Box display='flex' flexDirection='column'>
                                {selectedApplication?.source.toLowerCase() === 'eu' && (
                                  <Box display='flex' flexDirection='column'>
                                    <Box sx={comparisonClasses.compareSubText}>
                                      <b>Variation Number: </b>&nbsp; {item.variation_number}
                                    </Box>
                                  </Box>
                                )}
                                <Box
                                  sx={comparisonClasses.compareText}
                                  justifyContent='space-between'
                                  display='flex'>
                                  {!item.change?.[0] || item.change?.[0] === 'UNKNOWN' ? (
                                    <Box display='flex'>
                                      <b>Action details:</b>&nbsp;Unknown
                                    </Box>
                                  ) : (
                                    item.change
                                  )}
                                </Box>
                              </Box>
                            </Box>
                            <Box display='inline-flex' pl='20px' pb='10px' pt='10px'>
                              {selectedLabel?.submissionType === item.submission_type &&
                                selectedLabel?.submissionNum === item.submission_num &&
                                selectedLabel?.description === item.description &&
                                selectedLabel?.approvalDate === item.approval_date && (
                                  <Box width='160px'>
                                    <VivproSelect
                                      value={selectedLabel?.section}
                                      options={(item.label.sections || []).map((section: any) => ({
                                        value: section.section,
                                        label: SECTION_LABELS[section.section]
                                      }))}
                                      handleChange={(event: any) => {
                                        onLabelSelect(item, event.target.value);
                                      }}
                                      icon={undefined}
                                    />
                                  </Box>
                                )}
                            </Box>
                          </Box>
                        )}
                        <Divider
                          sx={{
                            mt: '20px',
                            mb: '20px'
                          }}
                        />
                      </React.Fragment>
                    ))}

                  {labelListToShow.length === 0 && (
                    <Box sx={{ textAlign: 'center', mt: '12px' }}>No labels found.</Box>
                  )}
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default React.memo(LabelList);
