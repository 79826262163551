const styles = {
  leftArrowIcon: { height: '14px', width: '14px', color: 'white.main' },
  changeLabelsText: {
    fontFamily: 'Mulish',
    fontSize: '14px',
    color: 'white.main',
    fontWeight: 600
  },
  secondaryHeaderWrapper: {
    padding: '8px 24px',
    backgroundColor: 'primary.50'
  },
  downloadIcon: {
    height: '16px',
    width: '16px',
    color: 'primary.700'
  },
  downloadComparisonText: {
    fontFamily: 'Mulish',
    fontSize: '13px',
    color: 'primary.700',
    fontWeight: 600
  },
  reportIcon: {
    height: '16px',
    width: '16px',
    color: 'primary.700'
  },
  generateAiSummaryText: {
    fontFamily: 'Mulish',
    fontSize: '13px',
    color: 'primary.700',
    fontWeight: 600
  },
  swapIcon: {
    height: '24px',
    width: '24px',
    color: 'primary.600'
  },
  compareButton: {
    height: '30px',
    padding: '15px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '16px',
    backgroundColor: 'primary.650',
    color: 'white.main',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: 'white.main',
      border: 1,
      borderColor: 'primary.main',
      borderStyle: 'solid',
      color: 'primary.600'
    },
    '&:disabled': {
      backgroundColor: 'gray.550',
      color: 'gray.background'
    }
  }
};

export default styles;
