import React, { useContext, useEffect, useState } from 'react';
import PDFComparisonDialog from '../../../components/Comparison/PDFComparisonDialog';
import ResultsAction from '../../../store/SearchResults/actions';
import { ModeOfComparisonDialog } from '../../../components/Comparison/ComparisonDialog';
import ResultsStore from '../../../store/SearchResults';
import SelectionView from './SelectionView';

interface CompareViewProps {
  applications: any[];
  applicationList?: any;
  // eslint-disable-next-line no-unused-vars
  removeFromComparison: (id: string) => void;
  clearComparison: () => void;
  exitCompareMode: () => void;
  compareMode: boolean;
  feature: string;
  sources: any;
  handleLabelSection?: any;
  handleCompareView?: any;
  comparisonApplications?: any;
}

const CompareView = ({
  compareMode,
  clearComparison,
  exitCompareMode,
  applications,
  applicationList,
  removeFromComparison,
  feature = '',
  sources = {},
  handleLabelSection,
  handleCompareView,
  comparisonApplications
}: CompareViewProps) => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);

  return resultsState?.labelComparisonSelection ? (
    <SelectionView
      open={resultsState?.labelComparisonSelection}
      onClose={() => {
        resultsDispatch({
          type: ResultsAction.SET_LABEL_COMPARISON_SELECTION,
          value: false
        });
      }}
      applicationList={applicationList}
      sources={sources}
      handleLabelSection={handleLabelSection}
      handleCompareView={handleCompareView}
      comparisonApplications={comparisonApplications}
    />
  ) : null;
};

export default React.memo(CompareView);
