const numberMixin: { [key: string]: any } = {
  py: 0,
  px: 0.5,
  borderRadius: '4px'
};

const textMixin: { [key: string]: any } = {
  fontWeight: 'bold',
  textTransform: 'uppercase'
};

const styles: any = {
  datagrid: {
    border: 'none',
    fontSize: 14,
    maxWidth: 'calc(100vw - 96px)',
    m: 'auto',
    '& .table-header': {
      width: '100%',
      color: 'primary.main',
      fontWeight: 'bold',
      bgcolor: 'primary.backgroundDark'
    },
    '& .MuiDataGrid-main': {
      border: '.5px solid #0A806D',
      borderRadius: '4px'
    },
    '& .table-row': {
      pr: 3
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
      textTransform: 'capitalize',
      color: 'primary.600',
      fontSize: 14
    },
    '& 	.MuiDataGrid-row': {
      bgcolor: 'red'
    },
    '& .MuiDataGrid-cell:hover': {
      color: 'black.main'
    },
    '& .MuiTablePagination-displayedRows': {
      fontSize: 14
    },
    '& .MuiTablePagination-selectLabel': {
      fontSize: 14
    },
    '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
      display: 'none'
    },
    '& .MuiDataGrid-columnHeaderCheckbox': {
      bgcolor: 'primary.backgroundDark'
    }
  },
  redNumber: {
    ...numberMixin,
    bgcolor: 'red.main',
    color: 'white.main'
  },
  purpleNumber: {
    ...numberMixin,
    bgcolor: 'blue.main',
    color: 'white.main'
  },
  yellowNumber: {
    ...numberMixin,
    bgcolor: 'orange.main',
    color: 'white.main'
  },
  greenNumber: {
    ...numberMixin,
    bgcolor: 'green.accept',
    color: 'white.main'
  },
  orchidNumber: {
    ...numberMixin,
    bgcolor: 'pink.orchid',
    color: 'white.main'
  },
  grayNumber: {
    ...numberMixin,
    bgcolor: 'gray.main',
    color: 'white.main'
  },
  redText: {
    color: 'red.main',
    ...textMixin
  },
  yellowText: {
    color: 'orange.main',
    ...textMixin
  },
  purpleText: {
    color: 'blue.main',
    ...textMixin
  },
  greenText: {
    color: 'green.accept',
    ...textMixin
  },
  grayText: {
    color: 'gray.main',
    ...textMixin
  },
  toolbarBtn: {
    color: 'gray.750',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '26px'
  },
  datagridCellText: {
    fontSize: 14,
    fontFamily: 'Mulish',
    lineHeight: '24px'
  },
  roundedMenu: {
    '& .MuiPaper-root': { padding: '8px 0' },
    '& .MuiList-root': { padding: 0 }
  },
  menuItem: {
    cursor: 'pointer',
    padding: '8px 24px !important'
  },
  listItemIcon: {
    minWidth: '14px !important'
  },
  svgIcon: { fontSize: 14, color: 'gray.750' },
  muiIcon: { fontSize: 14 },
  listItemText: {
    fontFamily: 'Mulish',
    fontSize: 12,
    fontWeight: 400,
    ml: 1,
    color: 'primary.darkVariant1'
  }
};

export default styles;
