/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { Virtuoso } from 'react-virtuoso';

import { chunk } from 'lodash';
import Card from './Card';
import { RELEVANCE_SOURCE } from '../constants';

const VirtualizedGrid = ({
  applications,
  hideApplication,
  triggerChatRIA,
  setFavorite,
  setInProject,
  setIsSubscribed,
  handleCompareView,
  labelComparisonSelection,
  labelComparisonLimit,
  comparisonApplications,
  handleLabelSection,
  selectedLabelSelection,
  applyUseWindowScroll = true,
  customHeight = '100%'
}: any) => {
  const filterAndChunkApplications = (application: any[], relevance: string) => {
    return chunk(
      application
        .filter(app => {
          if (relevance === RELEVANCE_SOURCE.RELEVANT) {
            return app.relevance_score !== RELEVANCE_SOURCE.SUGGESTED;
          }
          return app.relevance_score === RELEVANCE_SOURCE.SUGGESTED;
        })
        .map((app, index, arr) => ({
          ...app,
          showDottedTitle:
            index > 0 && app.groupTitle.toLowerCase() === arr[index - 1].groupTitle.toLowerCase()
        })),
      4
    );
  };

  const combinedApplications = useMemo(() => {
    const suggestedApplications = filterAndChunkApplications(
      applications,
      RELEVANCE_SOURCE.SUGGESTED
    );
    const relevantApplications = filterAndChunkApplications(
      applications,
      RELEVANCE_SOURCE.RELEVANT
    );
    return [
      ...relevantApplications,
      ...(suggestedApplications.length > 0
        ? [
            [
              {
                renderType: 'heading',
                content: 'Suggested results'
              }
            ]
          ]
        : []),
      ...suggestedApplications
    ];
  }, [applications]);
  return (
    <Virtuoso
      useWindowScroll={applyUseWindowScroll}
      style={{
        width: '100%',
        height: customHeight,
        overflowY: 'auto'
      }}
      totalCount={combinedApplications.length}
      data={combinedApplications ?? []}
      // eslint-disable-next-line
      itemContent={(index: number, groupData: any) => (
        <Grid container maxWidth='lg' mx='auto'>
          {groupData?.map((data: any, i: number) => (
            <Grid
              // eslint-disable-next-line react/no-array-index-key
              key={`${data.index}-${i}`}
              item
              pl={2.5}
              pt={2.5}
              pr={0.5}
              xs={12}
              md={data.renderType === 'heading' ? 12 : 6}
              lg={data.renderType === 'heading' ? 12 : 3}
              xl={data.renderType === 'heading' ? 12 : 3}>
              {data.renderType === 'heading' ? (
                <Stack
                  mt={10}
                  direction='row'
                  justifyContent='flex-start'
                  width='100%'
                  sx={{ bgcolor: 'gray.200', borderRadius: '6px', px: 1.5, py: 0.5 }}>
                  <Typography fontSize={13} sx={{ fontWeight: 700, color: 'gray.800' }}>
                    Similar Results You Might Like!
                  </Typography>
                </Stack>
              ) : (
                <Card
                  data={data}
                  showDottedTitle={
                    'showDottedTitle' in data
                      ? data?.showDottedTitle
                      : data?.groupTitle?.toLowerCase() === // @ts-ignore
                        combinedApplications[index - 1]?.groupTitle?.toLowerCase()
                  }
                  applicationIndex={index}
                  hideApplication={hideApplication}
                  triggerChatRIA={triggerChatRIA}
                  setFavoriteInHook={setFavorite}
                  setInProjectInHook={setInProject}
                  setSubscriptionInHook={setIsSubscribed}
                  handleCompareView={handleCompareView}
                  showCompare={labelComparisonSelection}
                  inCompare={
                    comparisonApplications?.some((d: any) => d.identifier === data.identifier) ??
                    false
                  }
                  disableCompare={comparisonApplications?.length >= labelComparisonLimit}
                  handleLabelSection={handleLabelSection}
                  inLabelSection={
                    selectedLabelSelection?.some(
                      (d: any) => d.labelSelectionIdentifier === data.labelSelectionIdentifier
                    ) ?? false
                  }
                />
              )}
            </Grid>
          ))}
        </Grid>
      )}
    />
  );
};

export default React.memo(VirtualizedGrid);
